import { AdminClassTheme, DrawerTheme, GeneralTheme } from './themes/types';

import { drawerTheme } from './drawer'
import { generalTheme } from './general'

export interface RDTheme {
    general?: GeneralTheme;
    drawer?: DrawerTheme;
    adminClass?: AdminClassTheme;
}

export interface ThemeProps {
    theme: RDTheme;
}

const demo: RDTheme = {
    general: generalTheme,
    drawer: drawerTheme,
}

export default demo;
