import GameTypeActionTypes from "./types";
import Request from 'lib/request';

export const getGameTypeList = (limit:number,offset:number) => async (dispatch: any) => {
  try {
    const response = await Request.get('/admin/game-types?offset='+offset+'&limit='+limit)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.GET_GAMETYPELIST, payload });
  } catch (error) {
    console.log(error);
  }
};


export const getGameType = (id:string) => async (dispatch: any) => {
  try {
    console.log('getting GT',id)
    const response = await Request.get('/admin/game-types/'+id)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.GET_GAMETYPE, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};

export const createGameType = (body: any) => async (dispatch: any) => {
  try {
    const response = await Request.post('/admin/game-types', body)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.CREATE_GAMETYPE, payload });
  } catch (error) {
    console.log(error);
  }
};


export const editGameType = (body: any,id:string) => async (dispatch: any) => {
  try {
    const response = await Request.patch('/admin/game-types/'+id, body)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.EDIT_GAMETYPE, payload });
  } catch (error) {
    console.log(error);
  }
};


export const deleteGameType = (id:string) => async (dispatch: any) => {
  try {
    console.log('deleting ',id);
    const response = await Request.delete('/admin/game-types/'+id)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.DELETE_GAMETYPE, payload });
  } catch (error) {
    console.log(error);
  }
};

export const getGameModule = (id:string) => async (dispatch: any) => {
  try {
    console.log('getting GT',id)
    const response = await Request.get('/admin/game-modules/'+id)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.GET_GAMEMODULE, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};

export const createGameModule = (body: any) => async (dispatch: any) => {
  try {
    const response = await Request.post('/admin/game-modules', body)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.CREATE_GAMEMODULE, payload });
  } catch (error) {
    console.log(error);
  }
};


export const editGameModule = (body: any,id:string) => async (dispatch: any) => {
  try {
    const response = await Request.patch('/admin/game-modules/'+id, body)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.EDIT_GAMEMODULE, payload });
  } catch (error) {
    console.log(error);
  }
};

export const deleteGameModule = (id:string) => async (dispatch: any) => {
  try {
    console.log('deleting ',id);
    const response = await Request.delete('/admin/game-modules/'+id)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.DELETE_GAMEMODULE, payload });
  } catch (error) {
    console.log(error);
  }
};

export const getGamePage = (id:string) => async (dispatch: any) => {
  try {
    console.log('getting GP',id)
    const response = await Request.get('/admin/game-pages/'+id)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.GET_GAMEPAGE, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};

export const createGamePage = (body: any) => async (dispatch: any) => {
  try {
    const response = await Request.post('/admin/game-pages', body)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.CREATE_GAMEPAGE, payload });
  } catch (error) {
    console.log(error);
  }
};


export const editGamePage = (body: any,id:string) => async (dispatch: any) => {
  try {
    const response = await Request.patch('/admin/game-pages/'+id, body)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.EDIT_GAMEPAGE, payload });
  } catch (error) {
    console.log(error);
  }
};

export const deleteGamePage = (id:string) => async (dispatch: any) => {
  try {
    console.log('deleting ',id);
    const response = await Request.delete('/admin/game-pages/'+id)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.DELETE_GAMEPAGE, payload });
  } catch (error) {
    console.log(error);
  }
};

export const getGameHint = (id:string) => async (dispatch: any) => {
  try {
    console.log('getting GH',id)
    const response = await Request.get('/admin/game-hints/'+id)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.GET_GAMEHINT, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};

export const createGameHint = (body: any) => async (dispatch: any) => {
  try {
    const response = await Request.post('/admin/game-hints', body)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.CREATE_GAMEHINT, payload });
  } catch (error) {
    console.log(error);
  }
};


export const editGameHint = (body: any,id:string) => async (dispatch: any) => {
  try {
    const response = await Request.patch('/admin/game-hints/'+id, body)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.EDIT_GAMEHINT, payload });
  } catch (error) {
    console.log(error);
  }
};

export const deleteGameHint = (id:string) => async (dispatch: any) => {
  try {
    console.log('deleting ',id);
    const response = await Request.delete('/admin/game-hints/'+id)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.DELETE_GAMEHINT, payload });
  } catch (error) {
    console.log(error);
  }
};


export const createGamePoint = (body: any) => async (dispatch: any) => {
  try {
    const response = await Request.post('/admin/game-points', body)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.CREATE_GAMEPOINT, payload });
  } catch (error) {
    console.log(error);
  }
};


export const editGamePoint = (body: any,id:string) => async (dispatch: any) => {
  try {
    const response = await Request.patch('/admin/game-points/'+id, body)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.EDIT_GAMEPOINT, payload });
  } catch (error) {
    console.log(error);
  }
};

export const deleteGamePoint = (id:string) => async (dispatch: any) => {
  try {
    console.log('deleting ',id);
    const response = await Request.delete('/admin/game-points/'+id)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.DELETE_GAMEPOINT, payload });
  } catch (error) {
    console.log(error);
  }
};

export const getGameSessionsList = (limit:number,offset:number) => async (dispatch: any) => {
  try {
    console.log('getGameSessionList')
    const response = await Request.get('/admin/game-sessions?offset='+offset+'&limit='+limit)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.GET_GAMESESSIONLIST, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};

export const createGameSession = (body: any) => async (dispatch: any) => {
  try {
    const response = await Request.post('/admin/game-sessions', body)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.CREATE_GAMESESSION, payload });
  } catch (error) {
    console.log(error);
  }
};

export const deleteGameSession = (id:string) => async (dispatch: any) => {
  try {
    console.log('deleting ',id);
    const response = await Request.delete('/admin/game-sessions/'+id)
    const payload = response.data;
    dispatch({ type: GameTypeActionTypes.DELETE_GAMESESSION, payload });
  } catch (error) {
    console.log(error);
  }
};

export const clearResponse = () => async (dispatch: any) => {
  const payload = null;
  dispatch({ type: GameTypeActionTypes.CLEAR_RESPONSE, payload });

};
