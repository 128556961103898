import { Layout } from "antd";
import styled from "styled-components";
import { lighten, darken } from "polished";

export const StyledSider = styled(Layout.Sider).attrs(props => ({
	// or we can define dynamic ones
	color: props.color || "red"
}))`
	background-color: ${props => props.color} !important;
	box-shadow: fade(@primary-color, 10%) 0 0 28px 0;
	z-index: 10;
	:global {
		.ant-layout-sider-children {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
	color: white;
	font-weight: bold;
	position: fixed;
	height: 100vh;
	overflow-y: auto;

	::-webkit-scrollbar {
	display: none;
	}
	.disable-scrollbars::-webkit-scrollbar {
		width: 0px;
		background: transparent; /* Chrome/Safari/Webkit */
	}
	.disable-scrollbars {
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none;  /* IE 10+ */
	}

`;

// export const Transition = styled.div`
// .active {
//   position: relative;
//   padding-top: 70px;
//   transition: all 0ms ease-in;
//   height: 100%;
// }
// .hidden {
// 	position: fixed;
// 	padding-top: 70px;
// 	/* transition: all 20ms ease-out; */
// 	transition: all 0ms ease-in;
// 	height: 100%;
// 	overflow-y: auto;
// }
// `;

export const StyledLogoContainer = styled.div`
	z-index: 1;
	height: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 24px;
	text-align: center;
	/* box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.2); */
	.logo {
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 36px;
			margin-right: 8px;
		}

		h1 {
			vertical-align: text-bottom;
			font-size: 16px;
			text-transform: uppercase;
			display: inline-block;
			font-weight: 700;
			color: @primary-color;
			white-space: nowrap;
			margin-bottom: 0;
			.text-gradient();

			:local {
				animation: fadeRightIn 300ms @ease-in-out;
				animation-fill-mode: both;
			}
		}
	}
`;

export const StyledMenuContainer = styled.div.attrs(props => ({
	// or we can define dynamic ones
	color: props.color || "red"
}))`
	.menuContainer {
		height: ~"calc(100vh - 120px)";
		overflow-x: hidden;
		flex: 1;
		padding: 24px 0;
		
		// background-color: #337187;

		&::-webkit-scrollbar-thumb {
			background-color: transparent;
		}

		&:hover {
			&::-webkit-scrollbar-thumb {
				background-color: rgba(0, 0, 0, 0.2);
				// background-color: #337187;
			}
		}

		:global {
			.ant-menu-inline {
				border-right: none;
			}
		}
	}
	.ant-drawer-wrapper-body{
		// background-color: #337187;
	}

	.ant-menu{
		box-shadow: none;
		// background-color: #397C96;
	}
	.ant-menu-item{
		box-shadow: none;
		&:hover,&.ant-menu-item-selected{
			background-color: ${props => darken(0.05, props.color)};
		}
	}

	.ant-menu-submenu{
		.ant-menu-submenu-title{
			&:hover{
				background-color: ${props => darken(0.05, props.color)};
			}
		}
	}
	.ant-menu-dark .ant-menu-inline.ant-menu-sub{
		background-color ${props => props.color}
	}
	.ant-menu-submenu,.ant-menu-sub{
		// background-color: #397C96;
		box-shadow: none;
		.ant-menu-item{
			box-shadow: none;
			// background-color: #397C96;
		}
	}
	.ant-menu-dark .ant-menu-inline.ant-menu-sub{
		box-shadow: none;
	}
`;
