import * as yup from "yup";

import {
  Avatar,
  Button,
  Card,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Layout,
  Menu,
  Modal,
  PageHeader,
  Popconfirm,
  Select,
  Table,
} from "antd";
import { EllipsisOutlined, UserOutlined } from "@ant-design/icons";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  clearResponse,
  createVideo,
  deleteVideo,
  editVideo,
  getVideoRedeem,
  getVideosList,
} from "redux/videoManagement/actions";

import { AvatarUpload } from "components/upload/AvatarUpload";
import { StyledInternalContent } from "../components/InternalLayout";
import { StyledInternalHeader } from "../components/InternalLayout";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";

interface Props {}

const VideoEditSchema = yup.object({
  name: yup.string().required(),
});

const defaultInitialValues = {
  name: "",
  type: "YOUTUBE",
  link: "",
  price: 0,
  description: "",
  airDate: "",
  image: "",
};

const VideoManagementPage: React.FC<Props> = ({
  response,
  videoList,
  videoRedeem,
  getVideosList,
  getVideoRedeem,
  createVideo,
  editVideo,
  deleteVideo,
  clearResponse,
}: any) => {
  let history = useHistory();
  const [initialValue, setInitial] = useState(defaultInitialValues);
  const [showVideoEditModal, setShowVideoEditModal] = useState(false);
  const [showVideoRedeemModal, setShowVideoRedeemModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<any>();

  console.log("videoRedeem", videoRedeem);
  useEffect(() => {
    console.log("getting Video List");
    getVideosList(500, 0);
  }, []);

  useEffect(() => {
    console.log("response", response);

    if (response) {
      console.log("clearingResponse");
      clearResponse();
      setShowVideoEditModal(false);
      getVideosList(50, 0);
    }
  }, [response]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const handleAddVideo = () => {
    setSelectedVideo(null);
    setShowVideoEditModal(true);
  };

  const handleEditVideo = (id: string) => {
    console.log("id", id, videoList);
    setSelectedVideo(videoList.find((gt: any) => gt.id === id));
    setShowVideoEditModal(true);
  };
  const handleViewRedeem = (id: string) => {
    console.log("id", id, videoList);

    setSelectedVideo(videoList.find((gt: any) => gt.id === id));
    getVideoRedeem(id);
    setShowVideoRedeemModal(true);
  };

  useEffect(() => {
    if (selectedVideo) {
      let newInitialFormData = { ...initialValue };
      if (selectedVideo.name && selectedVideo.name !== "")
        newInitialFormData.name = selectedVideo.name;
      if (selectedVideo.type && selectedVideo.type !== "")
        newInitialFormData.type = selectedVideo.type;
      if (selectedVideo.link && selectedVideo.link !== "")
        newInitialFormData.link = selectedVideo.link;
      if (selectedVideo.price >= 0)
        newInitialFormData.price = selectedVideo.price;
      if (selectedVideo.description && selectedVideo.description !== "")
        newInitialFormData.description = selectedVideo.description;
      if (selectedVideo.airDate && selectedVideo.airDate !== "")
        newInitialFormData.airDate = selectedVideo.airDate;
      if (selectedVideo.image && selectedVideo.image !== "")
        newInitialFormData.image = selectedVideo.image;

      setInitial(newInitialFormData);
      resetMyForm();
    } else {
      setInitial(defaultInitialValues);
      resetMyForm();
    }
  }, [selectedVideo]);

  const handleDeleteVideo = (id: string) => {
    deleteVideo(id);
  };

  const videoMenu = (id: string) => (
    <Menu>
      <Menu.Item onClick={() => handleViewRedeem(id)}>
        {"View Redeemer"}
      </Menu.Item>
      <Menu.Item onClick={() => handleEditVideo(id)}>{"Edit"}</Menu.Item>
      <Menu.Item>
        <Popconfirm
          title="Are you sure you want to delete this video?."
          onConfirm={() => handleDeleteVideo(id)}
          okText="Yes"
          cancelText="No"
        >
          <a>{"Delete"}</a>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const redeemColumns = [
    {
      title: "Redeemer",
      dataIndex: "userId",
      key: "userId",
      render: (text: string, record: any) => (
        <a
          onClick={(e: any) => {
            e?.domEvent?.stopPropagation();
            history.push("/userDetail/" + text);
          }}
        >
          {decodeURIComponent(record.users.username)}
        </a>
      ),
    },
    {
      title: "Name",
      dataIndex: "userId",
      key: "firstName",
      render: (text: string, record: any) => (
        <span>
          {record.users.firstName
            ? decodeURIComponent(record.users.firstName)
            : ""}{" "}
          {record.users.firstName
            ? decodeURIComponent(record.users.lastName)
            : ""}
        </span>
      ),
    },
    {
      title: "Tel",
      dataIndex: "userId",
      key: "tel",
      render: (text: string, record: any) => <span>{record.users.tel}</span>,
    },
  ];

  const columns: any = [
    {
      title: "Image",
      dataIndex: "avatar",
      key: "avatar",
      render: (text: string, record: any) => (
        <Avatar shape="square" size={64} icon={<UserOutlined />} src={text} />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Air Date",
      dataIndex: "airDate",
      key: "airDate",
      sorter: (a: any, b: any) => a.airDate?.localeCompare(b.airDate),
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "descend",
      render: (text: string) => <span>{moment(text).format("LLL")}</span>,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (text: string, record: any) => (
        <Dropdown key="more" overlay={videoMenu(record.id)}>
          <Button
            style={{
              border: "none",
              padding: 0,
            }}
          >
            <EllipsisOutlined
              style={{
                fontSize: 20,
                verticalAlign: "top",
              }}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  let submitMyForm = () => {};
  let resetMyForm = () => {};
  const handleOk = () => {
    submitMyForm();
  };

  const handleCancel = () => {
    resetMyForm();
    setShowVideoEditModal(false);
  };

  const handleRedeemerCancel = () => {
    setShowVideoRedeemModal(false);
  };
  return (
    <Layout>
      <StyledInternalHeader>
        <br />
        <PageHeader
          title={"Video Management"}
          style={{ paddingBottom: 0 }}
          extra={[
            <Button
              type="primary"
              key={"create"}
              onClick={() => handleAddVideo()}
            >
              {"Create new video"}
            </Button>,
          ]}
        ></PageHeader>
        <br />
      </StyledInternalHeader>
      <StyledInternalContent>
        <Card>
          <Table
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={columns}
            dataSource={videoList}
          />
        </Card>
        <Modal
          visible={showVideoRedeemModal}
          title={"Video Redeem"}
          onOk={() => handleRedeemerCancel()}
          onCancel={() => handleRedeemerCancel()}
          footer={[
            <Button key="accept" type="primary" onClick={handleRedeemerCancel}>
              {"OK"}
            </Button>,
          ]}
        >
          <Table
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={redeemColumns}
            dataSource={videoRedeem}
          />
        </Modal>
        <Modal
          visible={showVideoEditModal}
          title={"Edit Video"}
          onOk={() => handleOk()}
          onCancel={() => handleCancel()}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              {"Cancel"}
            </Button>,
            <Button key="accept" type="primary" onClick={handleOk}>
              {"OK"}
            </Button>,
          ]}
        >
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={(data, { setSubmitting }) => {
              console.log("data", selectedVideo, data);
              if (selectedVideo) {
                editVideo(data, selectedVideo.id);
              } else {
                createVideo(data);
              }
              setSubmitting(false);
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={VideoEditSchema}
          >
            {({
              errors,
              handleSubmit,
              submitForm,
              resetForm,
              values,
              setFieldValue,
            }) => {
              submitMyForm = () => {
                submitForm();
              };
              resetMyForm = () => {
                resetForm();
              };
              return (
                <Form onFinish={handleSubmit} {...formItemLayout}>
                  <Form.Item label="Image">
                    <AvatarUpload
                      value={values.image}
                      onChange={(e: any) => setFieldValue("image", e)}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.name ? "error" : "success"}
                    help={errors.name}
                    label={"Name"}
                    required
                  >
                    <Field
                      name={"name"}
                      placeholder={"name"}
                      type="input"
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.type ? "error" : "success"}
                    help={errors.type}
                    label={"Type"}
                    required
                  >
                    <Select
                      placeholder="Select Type"
                      onChange={(value: any) => {
                        setFieldValue("type", value);
                      }}
                      value={values.type}
                    >
                      <Select.Option value="YOUTUBE">Youtube</Select.Option>
                      <Select.Option value="LIVE">Live</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.link ? "error" : "success"}
                    help={errors.link}
                    label={"Link"}
                    required
                  >
                    <Field
                      name={"link"}
                      placeholder={"URL"}
                      type="input"
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.price ? "error" : "success"}
                    help={errors.price}
                    label={"Price"}
                  >
                    <InputNumber
                      value={values.price}
                      onChange={(output: any) => setFieldValue("price", output)}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.description ? "error" : "success"}
                    help={errors.description}
                    label={"Description"}
                    required
                  >
                    <Field
                      name={"description"}
                      placeholder={"Description"}
                      type="input"
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.airDate ? "error" : "success"}
                    help={errors.airDate}
                    label={"Air Date"}
                    required
                  >
                    <DatePicker
                      showTime
                      onChange={(date, dateString) => {
                        setFieldValue(
                          "airDate",
                          moment(dateString).toISOString()
                        );
                      }}
                      value={
                        values.airDate ? moment(values.airDate) : undefined
                      }
                    />
                  </Form.Item>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </StyledInternalContent>
    </Layout>
  );
};

const mapStateToProps = (state: any) => {
  const { videoManagement } = state;

  return {
    response: videoManagement.response,
    videoList: videoManagement.videoList,
    videoRedeem: videoManagement.videoRedeem,
  };
};

export default connect(mapStateToProps, {
  getVideosList,
  getVideoRedeem,
  createVideo,
  editVideo,
  deleteVideo,
  clearResponse,
})(VideoManagementPage);
