import SettingsActionTypes from './types';

const INITIAL_STATE = {
  response:null,
  currentVersion: null,
  promotionResponse: null,
  currentPromotion: null,
  caps_count:0,
  users_count:0,
  sessions_count:0,
  redeemed_caps_count:0
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SettingsActionTypes.GET_VERSION:
      return { ...state, currentVersion: action.payload };
    case SettingsActionTypes.EDIT_VERSION:
      return { ...state, response: action.payload };
    case SettingsActionTypes.GET_PROMOTION:
      return { ...state, currentPromotion: action.payload };
    case SettingsActionTypes.EDIT_PROMOTION:
      return { ...state, promotionResponse: action.payload };
      case SettingsActionTypes.GET_CAPS_COUNT:
        return { ...state, caps_count: action.payload.count };
        case SettingsActionTypes.GET_REDEEMED_CAPS_COUNT:
          return { ...state, redeemed_caps_count: action.payload.count };
          case SettingsActionTypes.GET_USERS_COUNT:
            return { ...state, users_count: action.payload.count };
            case SettingsActionTypes.GET_SESSIONS_COUNT:
              return { ...state, sessions_count: action.payload.count };
      case SettingsActionTypes.CLEAR_RESPONSE:
        return { ...state, response: action.payload, promotionResponse: action.payload };
    default:
      return state;
  }
};
