import * as yup from "yup";

import { Button, Col, Form, Input, Layout, PageHeader, Row } from "antd";
import { Field, Formik } from "formik";
import { StyledHeader, StyledInternalContent } from "components/InternalLayout";

import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { forgetPassword } from "redux/user/actions";

const ForgetPasswordPage: React.FC = ({ forgetPassword }: any) => {
	const forgetPasswordSchema = yup.object({
		username: yup.string().required(),
	});

	return (
		<Layout>
			<StyledHeader>
				<br />
				<PageHeader
					title={"Forget Password"}
					style={{ paddingBottom: 0 }}
				></PageHeader>
				<br />
			</StyledHeader>
			<StyledInternalContent>
				<Row justify="center" align="middle" style={{ height: "100vh" }}>
					<Col xs={20} md={8}>
						<Row>
							<Formik
								initialValues={{ username: "" }}
								onSubmit={async (data) => {
									forgetPassword(data);
								}}
								validateOnChange={false}
								validateOnBlur={false}
								validationSchema={forgetPasswordSchema}
							>
								{({ isSubmitting, errors, handleSubmit }) => (
									<Form
										onFinish={handleSubmit}
										style={{
											maxWidth: "100%",
											textAlign: "center",
										}}
									>
										{/*props.errorMessage !== "" && (
										<Alert message={props.errorMessage} type="error" />
									)*/}
										<Form.Item
											label={"Username"}
											validateStatus={errors.username ? "error" : "success"}
											help={errors.username}
										>
											<Field
												name="username"
												placeholder={"Username"}
												type="input"
												as={Input}
												prefix={
													<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
												}
											/>
										</Form.Item>
										<Form.Item>
											<Button
												htmlType="submit"
												className="login-form-button"
												disabled={isSubmitting}
												size="large"
												style={{ width: "100%" }}
												shape="round"
											>
												{"Request Password Reset"}
											</Button>
										</Form.Item>
									</Form>
								)}
							</Formik>
						</Row>
					</Col>
				</Row>
			</StyledInternalContent>
		</Layout>
	);
};

const mapStateToProps = (state: any) => {};

export default connect(mapStateToProps, { forgetPassword })(ForgetPasswordPage);
