const RewardManagementActionTypes = {
  GET_REWARDSLIST: 'GET_REWARDSLIST',
  GET_LOTTERYLIST: 'GET_LOTTERYLIST',
  CREATE_REWARD: 'CREATE_REWARD',
  CREATE_LOTTERY: 'CREATE_LOTTERY',
  GET_REWARD: 'GET_REWARD',
  GET_REWARD_REDEMPTION: 'GET_REWARD_REDEMPTION',
  EDIT_REWARD: 'EDIT_REWARD',
  DELETE_REWARD: 'DELETE_REWARD',
  DELETE_REDEMPTION: 'DELETE_REDEMPTION',
  CLEAR_RESPONSE: 'CLEAR_RESPONSE',
};

export default RewardManagementActionTypes;
