import * as yup from "yup";

import { Alert, Button, Col, Form, Input, Row, Typography } from "antd";
import { Field, Formik } from "formik";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import React, { useContext, useEffect } from "react";

import Request from "lib/request";
import { connect } from "react-redux";
import { login } from "redux/user/actions";
import { useHistory } from "react-router-dom";

const LoginPage: React.FC = ({ currentUser, login }: any) => {
	const { Title } = Typography;
	let history = useHistory();
	console.log("Login");
	const loginSchemaA = yup.object({
		username: yup.string().required(),
	});

	useEffect(() => {
		//login({ username: "admin", password: "rootroot" });
	}, []);

	console.log(currentUser);

	useEffect(() => {
		if (currentUser?.user) {
			localStorage.setItem("access-token", currentUser.accessToken);
			history.push("/dashboard");
		}
	}, [currentUser?.user?.id]);

	const onLoggedin = () => {
		history.push("/");
	};

	return (
		<div>
			<Row justify="center" align="middle" style={{ height: "100vh" }}>
				<Col xs={20} md={8}>
					<img
						src={
							"https://bucket-development.s3-ap-southeast-1.amazonaws.com/statics/app-icon.png"
						}
						width="250"
						alt=""
						title="schedule-new-S"
					/>
				</Col>
				<Col xs={20} md={8}>
					<Row>
						<Formik
							initialValues={{ username: "", password: "" }}
							onSubmit={async (data) => {
								login(data);
							}}
							validateOnChange={false}
							validateOnBlur={false}
							validationSchema={loginSchemaA}
						>
							{({ values, isSubmitting, errors, handleSubmit }) => (
								<Form
									onFinish={handleSubmit}
									style={{
										maxWidth: "100%",
										textAlign: "center",
									}}
								>
									{/*props.errorMessage !== "" && (
										<Alert message={props.errorMessage} type="error" />
									)*/}
									<Form.Item
										label={"Username"}
										validateStatus={errors.username ? "error" : "success"}
										help={errors.username}
									>
										<Field
											name="username"
											placeholder={"Username"}
											type="input"
											as={Input}
											prefix={
												<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
											}
										/>
									</Form.Item>
									<Form.Item label={"Password"}>
										<Field
											name="password"
											placeholder={"Password"}
											type="password"
											as={Input}
											prefix={
												<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
											}
										/>
									</Form.Item>
									<Form.Item>
										<Button
											htmlType="submit"
											className="login-form-button"
											disabled={isSubmitting}
											size="large"
											style={{ width: "100%" }}
											shape="round"
										>
											{"Login"}
										</Button>
									</Form.Item>
								</Form>
							)}
						</Formik>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	const { user } = state;

	return {
		currentUser: user.currentUser,
	};
};

export default connect(mapStateToProps, { login })(LoginPage);
