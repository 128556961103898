import "./App.css";

import { Provider, ReactReduxContext } from "react-redux";
import configureStore, { history } from "./redux/configureStore";

import { ConnectedRouter } from "connected-react-router";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";
import { ThemeProvider } from "styled-components";
import theme from "./themes";

const { store } = configureStore();

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<Provider store={store} context={ReactReduxContext}>
			<ConnectedRouter history={history} context={ReactReduxContext}>
				<Routes />
			</ConnectedRouter>
		</Provider>
	</ThemeProvider>,
	document.querySelector("#root")
);
