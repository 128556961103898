import CapManagementActionTypes from './types';

const INITIAL_STATE = {
  response:null,
  capList:null,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CapManagementActionTypes.GET_CAPSLIST:
      return { ...state, capList: action.payload };
    case CapManagementActionTypes.ADD_CAP:
      return { ...state, response: action.payload };
    case CapManagementActionTypes.UPLOAD_CAP_CSV:
      return { ...state, response: action.payload };
    case CapManagementActionTypes.DELETE_CAP:
      return { ...state, response: action.payload };
    case CapManagementActionTypes.CLEAR_RESPONSE:
      return { ...state, response: action.payload };


    default:
      return state;
  }
};
