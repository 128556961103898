import { Layout, PageHeader } from "antd";
import {
	StyledHeader,
	StyledInternalContent,
} from "../components/InternalLayout";

import React from "react";

const DeleteDataPage: React.FC = () => {
	return (
		<Layout>
			<StyledHeader>
				<br />
				<PageHeader
					title={"Delete Data"}
					style={{ paddingBottom: 0 }}
					extra={null}
				></PageHeader>
				<br />
			</StyledHeader>
			<StyledInternalContent>
				<p>Data Deletion Instruction</p>
				<p>
					1. Please send an email with your title “Data Delete Request” to{" "}
					<a href="mailto:nick1_1998@yahoo.com">nick1_1998@yahoo.com</a>
				</p>
				<p>2. In the body of the email please enter your facebook name.</p>
				<p>
					3. We’ll send the confirmation email back to you when we deleted your
					data.
				</p>
			</StyledInternalContent>
		</Layout>
	);
};

export default DeleteDataPage;
