import * as yup from "yup";

import {
	Button,
	Card,
	Col,
	Dropdown,
	Form,
	Input,
	InputNumber,
	Layout,
	Menu,
	Modal,
	PageHeader,
	Popconfirm,
	Row,
	Select,
	Table,
} from "antd";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
	StyledInternalContent,
	StyledInternalHeader,
} from "../components/InternalLayout";
import {
	clearResponse,
	createGameHint,
	createGamePoint,
	deleteGameHint,
	deleteGamePoint,
	editGameHint,
	editGamePoint,
	getGamePage,
} from "redux/gameType/actions";
import { useHistory, useParams } from "react-router-dom";

import { EllipsisOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

interface Props {}

const GameHintEditSchema = yup.object({
	name: yup.string().required(),
});
const GamePointEditSchema = yup.object({
	x: yup.string().required(),
	y: yup.string().required(),
});

const defaultInitialHintValues = {
	name: "",
	type: "",
	hint: "",
	index: 0,
	cost: 0,
	gamePageId: "",
};

const defaultInitialPointValues = {
	x: "",
	y: "",
	radius: "",
	gamePageId: "",
};

const GamePageDetailPage: React.FC<Props> = ({
	currentGamePage,
	response,
	clearResponse,
	createGameHint,
	deleteGameHint,
	editGameHint,
	createGamePoint,
	deleteGamePoint,
	editGamePoint,
	getGamePage,
}: any) => {
	let history = useHistory();
	let { gamePageID } = useParams<any>();
	const [initialHintValue, setInitialHint] = useState(defaultInitialHintValues);
	const [initialPointValue, setInitialPoint] = useState(
		defaultInitialPointValues
	);
	const [showGameHintEditModal, setShowGameHintEditModal] = useState(false);
	const [selectedGameHint, setSelectedGameHint] = useState<any>();
	const [showGamePointEditModal, setShowGamePointEditModal] = useState(false);
	const [selectedGamePoint, setSelectedGamePoint] = useState<any>();

	useEffect(() => {
		console.log("getting Game Type", gamePageID);
		getGamePage(gamePageID);
	}, []);

	useEffect(() => {
		console.log("response", response);

		if (response) {
			console.log("clearingResponse");
			clearResponse();
			setSelectedGameHint(null);
			setShowGameHintEditModal(false);
			setSelectedGamePoint(null);
			setShowGamePointEditModal(false);
			getGamePage(gamePageID);
		}
	}, [response]);
	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	};

	const handleAddGameHint = () => {
		setSelectedGameHint(null);
		setShowGameHintEditModal(true);
	};

	const handleEditGameHint = (id: string) => {
		setSelectedGameHint(
			currentGamePage?.gameHints.find((gm: any) => gm.id === id)
		);
		setShowGameHintEditModal(true);
	};

	useEffect(() => {
		if (selectedGameHint) {
			let newInitialFormData = { ...initialHintValue };
			if (selectedGameHint.name && selectedGameHint.name !== "")
				newInitialFormData.name = selectedGameHint.name;
			if (selectedGameHint.type && selectedGameHint.type !== "")
				newInitialFormData.type = selectedGameHint.type;
			if (selectedGameHint.hint && selectedGameHint.hint !== "")
				newInitialFormData.hint = selectedGameHint.hint;
			if (selectedGameHint.index && selectedGameHint.index !== "")
				newInitialFormData.index = selectedGameHint.index;
			if (selectedGameHint.cost && selectedGameHint.cost !== 0)
				newInitialFormData.cost = selectedGameHint.cost;

			setInitialHint(newInitialFormData);
			resetHintForm();
		} else {
			setInitialHint(defaultInitialHintValues);
			resetHintForm();
		}
	}, [selectedGameHint]);

	const handleDeleteGameHint = (id: string) => {
		deleteGameHint(id);
	};

	const gameHintMenu = (id: string) => (
		<Menu>
			<Menu.Item onClick={() => handleEditGameHint(id)}>{"Edit"}</Menu.Item>
			<Menu.Item>
				<Popconfirm
					title="Are you sure you want to delete this gamePage?."
					onConfirm={() => handleDeleteGameHint(id)}
					okText="Yes"
					cancelText="No"
				>
					<a>{"Delete"}</a>
				</Popconfirm>
			</Menu.Item>
		</Menu>
	);
	const hintColumns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
		},
		{
			title: "Index",
			dataIndex: "index",
			key: "index",
		},
		{
			title: "Hint",
			dataIndex: "hint",
			key: "hint",
		},
		{
			title: "Cost",
			dataIndex: "cost",
			key: "cost",
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (text: string, record: any) => (
				<Dropdown key="more" overlay={gameHintMenu(record.id)}>
					<Button
						style={{
							border: "none",
							padding: 0,
						}}
					>
						<EllipsisOutlined
							type="ellipsis"
							style={{
								fontSize: 20,
								verticalAlign: "top",
							}}
						/>
					</Button>
				</Dropdown>
			),
		},
	];

	let submitHintForm = () => {};
	let resetHintForm = () => {};
	const handleHintOk = () => {
		submitHintForm();
	};

	const handleHintCancel = () => {
		resetHintForm();
		setShowGameHintEditModal(false);
	};

	const handleAddGamePoint = () => {
		setSelectedGamePoint(null);
		setShowGamePointEditModal(true);
	};

	const handleEditGamePoint = (id: string) => {
		setSelectedGamePoint(
			currentGamePage?.gamePoints.find((gm: any) => gm.id === id)
		);
		setShowGamePointEditModal(true);
	};

	useEffect(() => {
		if (selectedGamePoint) {
			let newInitialFormData = { ...initialPointValue };
			if (selectedGamePoint.x && selectedGamePoint.x !== "")
				newInitialFormData.x = selectedGamePoint.x;
			if (selectedGamePoint.y && selectedGamePoint.y !== "")
				newInitialFormData.y = selectedGamePoint.y;
			if (selectedGamePoint.radius && selectedGamePoint.radius !== "")
				newInitialFormData.radius = selectedGamePoint.radius;

			setInitialPoint(newInitialFormData);
			resetPointForm();
		} else {
			setInitialPoint(defaultInitialPointValues);
			resetPointForm();
		}
	}, [selectedGamePoint]);

	const handleDeleteGamePoint = (id: string) => {
		deleteGamePoint(id);
	};

	const gamePointMenu = (id: string) => (
		<Menu>
			<Menu.Item onClick={() => handleEditGamePoint(id)}>{"Edit"}</Menu.Item>
			<Menu.Item>
				<Popconfirm
					title="Are you sure you want to delete this game Point?."
					onConfirm={() => handleDeleteGamePoint(id)}
					okText="Yes"
					cancelText="No"
				>
					<a>{"Delete"}</a>
				</Popconfirm>
			</Menu.Item>
		</Menu>
	);
	const pointsColumns = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "X",
			dataIndex: "x",
			key: "x",
		},
		{
			title: "Y",
			dataIndex: "y",
			key: "y",
		},
		{
			title: "Radius",
			dataIndex: "radius",
			key: "radius",
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (text: string, record: any) => (
				<Dropdown key="more" overlay={gamePointMenu(record.id)}>
					<Button
						style={{
							border: "none",
							padding: 0,
						}}
					>
						<EllipsisOutlined
							type="ellipsis"
							style={{
								fontSize: 20,
								verticalAlign: "top",
							}}
						/>
					</Button>
				</Dropdown>
			),
		},
	];
	let submitPointForm = () => {};
	let resetPointForm = () => {};
	const handlePointOk = () => {
		submitPointForm();
	};

	const handlePointCancel = () => {
		resetPointForm();
		setShowGamePointEditModal(false);
	};
	return (
		<Layout>
			<StyledInternalHeader>
				<br />
				<PageHeader
					title={"Game Page Detail"}
					style={{ paddingBottom: 0 }}
					extra={[
						<Button
							type="primary"
							key={"createHint"}
							onClick={() => handleAddGameHint()}
						>
							{"Create new game hint"}
						</Button>,
						<Button
							type="primary"
							key={"createPoint"}
							onClick={() => handleAddGamePoint()}
						>
							{"Create new answer point"}
						</Button>,
					]}
				></PageHeader>
				<br />
			</StyledInternalHeader>
			<StyledInternalContent>
				{currentGamePage && (
					<Card style={{ marginBottom: 20 }}>
						<Row>
							{(currentGamePage.firstImage || currentGamePage.secondImage) && (
								<Col span={12}>
									{currentGamePage.firstImage && (
										<Row>
											<img
												alt="example"
												src={currentGamePage.firstImage}
												style={{ width: "100%" }}
											/>
										</Row>
									)}
									{currentGamePage.secondImage && (
										<Row>
											<img
												alt="example"
												src={currentGamePage.secondImage}
												style={{ width: "100%" }}
											/>
										</Row>
									)}
								</Col>
							)}

							<Col span={12}>
								<Row>
									<Col xs={8}>Name:</Col>
									<Col xs={16}>{currentGamePage.name}</Col>
								</Row>
								<Row>
									<Col xs={8}>Question:</Col>
									<Col xs={16}>{currentGamePage.question}</Col>
								</Row>
								<Row>
									<Col xs={8}>Answer:</Col>
									<Col xs={16}>{currentGamePage.answer}</Col>
								</Row>
								<Row>
									<Col xs={8}>Syllable:</Col>
									<Col xs={16}>{currentGamePage.syllable}</Col>
								</Row>
								<Row>
									<Col xs={8}>Points:</Col>
									<Col xs={16}>{currentGamePage.points}</Col>
								</Row>
								<Row>
									<Col xs={8}>Time:</Col>
									<Col xs={16}>{currentGamePage.time}</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				)}
				<Card title={"Hints"} style={{ marginBottom: 20 }}>
					<Table
						rowKey="id"
						scroll={{ x: "max-content" }}
						columns={hintColumns}
						dataSource={currentGamePage?.gameHints}
					/>
				</Card>
				<Card title={"Answer Points"} style={{ marginBottom: 20 }}>
					<Table
						rowKey="id"
						scroll={{ x: "max-content" }}
						columns={pointsColumns}
						dataSource={currentGamePage?.gamePoints}
					/>
				</Card>
				<Modal
					visible={showGameHintEditModal}
					title={"Edit Game Page"}
					onOk={() => handleHintOk()}
					onCancel={() => handleHintCancel()}
					footer={[
						<Button key="cancel" onClick={handleHintCancel}>
							{"Cancel"}
						</Button>,
						<Button key="accept" type="primary" onClick={handleHintOk}>
							{"OK"}
						</Button>,
					]}
				>
					<Formik
						enableReinitialize
						initialValues={initialHintValue}
						onSubmit={(data, { setSubmitting }) => {
							data.gamePageId = gamePageID;
							console.log("data", selectedGameHint, data);
							if (selectedGameHint) {
								editGameHint(data, selectedGameHint.id);
							} else {
								createGameHint(data);
							}
							setSubmitting(false);
						}}
						validateOnChange={false}
						validateOnBlur={false}
						validationSchema={GameHintEditSchema}
					>
						{({
							errors,
							handleSubmit,
							submitForm,
							resetForm,
							values,
							setFieldValue,
						}) => {
							submitHintForm = () => {
								submitForm();
							};
							resetHintForm = () => {
								resetForm();
							};
							return (
								<Form onFinish={handleSubmit} {...formItemLayout}>
									<Form.Item
										validateStatus={errors.name ? "error" : "success"}
										help={errors.name}
										label={"Name"}
										required
									>
										<Field
											name={"name"}
											placeholder={"name"}
											type="input"
											as={Input}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.type ? "error" : "success"}
										help={errors.type}
										label={"Type"}
										required
									>
										<Select
											placeholder={"Type"}
											onChange={(value: any) => {
												setFieldValue("type", value);
											}}
											value={values.type}
										>
											<Select.Option value={"Letter"} key={0}>
												{"Letter"}
											</Select.Option>
											<Select.Option value={"Reveal"} key={0}>
												{"Reveal"}
											</Select.Option>
										</Select>
									</Form.Item>
									<Form.Item
										validateStatus={errors.hint ? "error" : "success"}
										help={errors.hint}
										label={"Hint"}
									>
										<Field
											name={"hint"}
											placeholder={"hint"}
											type="input"
											as={Input}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.index ? "error" : "success"}
										help={errors.index}
										label={"Index"}
									>
										<InputNumber
											value={values.index}
											onChange={(output: any) => setFieldValue("index", output)}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.cost ? "error" : "success"}
										help={errors.cost}
										label={"Cost"}
									>
										<InputNumber
											value={values.cost}
											onChange={(output: any) => setFieldValue("cost", output)}
										/>
									</Form.Item>
								</Form>
							);
						}}
					</Formik>
				</Modal>
				<Modal
					visible={showGamePointEditModal}
					title={"Edit Game Point"}
					onOk={() => handlePointOk()}
					onCancel={() => handlePointCancel()}
					footer={[
						<Button key="cancel" onClick={handlePointCancel}>
							{"Cancel"}
						</Button>,
						<Button key="accept" type="primary" onClick={handlePointOk}>
							{"OK"}
						</Button>,
					]}
				>
					<Formik
						enableReinitialize
						initialValues={initialPointValue}
						onSubmit={(data, { setSubmitting }) => {
							data.gamePageId = gamePageID;
							console.log("data", selectedGamePoint, data);
							if (selectedGamePoint) {
								editGamePoint(data, selectedGamePoint.id);
							} else {
								createGamePoint(data);
							}
							setSubmitting(false);
						}}
						validateOnChange={false}
						validateOnBlur={false}
						validationSchema={GamePointEditSchema}
					>
						{({
							errors,
							handleSubmit,
							submitForm,
							resetForm,
							values,
							setFieldValue,
						}) => {
							submitPointForm = () => {
								submitForm();
							};
							resetPointForm = () => {
								resetForm();
							};
							return (
								<Form onFinish={handleSubmit} {...formItemLayout}>
									<Form.Item
										validateStatus={errors.x ? "error" : "success"}
										help={errors.x}
										label={"X"}
										required
									>
										<Field
											name={"x"}
											placeholder={"x"}
											type="input"
											as={Input}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.y ? "error" : "success"}
										help={errors.y}
										label={"Y"}
										required
									>
										<Field
											name={"y"}
											placeholder={"y"}
											type="input"
											as={Input}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.radius ? "error" : "success"}
										help={errors.radius}
										label={"Radius"}
										required
									>
										<Field
											name={"radius"}
											placeholder={"radius"}
											type="input"
											as={Input}
										/>
									</Form.Item>
								</Form>
							);
						}}
					</Formik>
				</Modal>
			</StyledInternalContent>
		</Layout>
	);
};

const mapStateToProps = (state: any) => {
	const { gameType } = state;

	return {
		response: gameType.response,
		currentGamePage: gameType.currentGamePage,
	};
};

export default connect(mapStateToProps, {
	getGamePage,
	createGameHint,
	editGameHint,
	deleteGameHint,
	createGamePoint,
	editGamePoint,
	deleteGamePoint,
	clearResponse,
})(GamePageDetailPage);
