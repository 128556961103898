import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  PageHeader,
  Row,
  DatePicker,
  Select,
  Statistic,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  StyledInternalContent,
  StyledInternalHeader,
} from "../components/InternalLayout";
import { clearResponse, getUser, editUser } from "redux/userManagement/actions";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";

import Title from "antd/lib/typography/Title";
import { UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Field, Formik } from "formik";
import { AvatarUpload } from "components/upload/AvatarUpload";
import moment from "moment";

interface Props {}

const UserEditSchema = yup.object({
  //firstName: yup.string().required(),
  //username: yup.string().required(),
  password: yup
    .string()
    .oneOf([yup.ref("confirmPassword")], "Passwords must match"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match"),
});
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const defaultInitialValues = {
  username: "",
  password: "",
  confirmPassword: "",
  avatar: "",
  firstName: "",
  lastName: "",
  facebookId: "",
  email: "",
  tel: "",
  gender: "",
  dob: "",
  address: "",
  province: "",
  role: "",
};

const UserDetailPage: React.FC<Props> = ({
  response,
  editUser,
  getUser,
  currentUser,
  clearResponse,
}: any) => {
  let history = useHistory();
  const { userID } = useParams<any>();
  const [initialValue, setInitial] = useState(defaultInitialValues);

  console.log("currentUser", currentUser);
  const [showUserEditModal, setShowUserEditModal] = useState(false);

  useEffect(() => {
    console.log("response", response);

    if (response) {
      console.log("clearingResponse");
      clearResponse();
      setShowUserEditModal(false);
      getUser(userID);
    }
  }, [response]);

  useEffect(() => {
    console.log("getting User", userID);
    getUser(userID);
  }, [userID]);

  const handleEditUser = () => {
    setShowUserEditModal(true);
  };

  let submitMyForm = () => {};
  let resetMyForm = () => {};
  const handleOk = () => {
    submitMyForm();
  };

  const handleCancel = () => {
    resetMyForm();
    setShowUserEditModal(false);
  };

  useEffect(() => {
    if (currentUser) {
      let newInitialFormData = { ...initialValue };
      if (currentUser.username && currentUser.username !== "")
        newInitialFormData.username = currentUser.username;
      if (currentUser.avatar && currentUser.avatar !== "")
        newInitialFormData.avatar = currentUser.avatar;
      if (currentUser.firstName && currentUser.firstName !== "")
        newInitialFormData.firstName = currentUser.firstName;
      if (currentUser.lastName && currentUser.lastName !== "")
        newInitialFormData.lastName = currentUser.lastName;
      if (currentUser.email && currentUser.email !== "")
        newInitialFormData.email = currentUser.email;
      if (currentUser.facebookId && currentUser.facebookId !== "")
        newInitialFormData.facebookId = currentUser.facebookId;
      if (currentUser.tel && currentUser.tel !== "")
        newInitialFormData.tel = currentUser.tel;
      if (currentUser.gender && currentUser.gender !== "")
        newInitialFormData.gender = currentUser.gender;
      if (currentUser.dob && currentUser.dob !== "")
        newInitialFormData.dob = currentUser.dob;
      if (currentUser.address && currentUser.address !== "")
        newInitialFormData.address = currentUser.address;
      if (currentUser.province && currentUser.province !== "")
        newInitialFormData.province = currentUser.province;
      if (currentUser.role && currentUser.role !== "")
        newInitialFormData.role = currentUser.role;

      setInitial(newInitialFormData);
      resetMyForm();
    } else {
      setInitial(defaultInitialValues);
      resetMyForm();
    }
  }, [currentUser]);

  const friendsColumns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text: string, record: any) => (
        <a
          onClick={(e: any) => {
            e?.domEvent?.stopPropagation();
            history.push("/userDetail/" + record.id);
          }}
        >
          {text}
        </a>
      ),
    },
  ];

  const transactionColumns = [
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];
  return (
    <Layout>
      <StyledInternalHeader>
        <br />
        <PageHeader
          title={"User Detail"}
          style={{ paddingBottom: 0 }}
          extra={[
            <Button
              type="primary"
              key={"create"}
              onClick={() => handleEditUser()}
            >
              {"Edit user"}
            </Button>,
          ]}
        ></PageHeader>
        <br />
      </StyledInternalHeader>
      <StyledInternalContent>
        {currentUser && (
          <>
            <Card>
              <Row>
                <Col span={8}>
                  <Row>
                    <Avatar
                      shape="square"
                      size={200}
                      icon={<UserOutlined />}
                      src={currentUser.avatar}
                    />
                  </Row>
                </Col>

                <Col span={12}>
                  <Row>
                    <Col xs={8}>Id:</Col>
                    <Col xs={16}>{currentUser.id}</Col>
                  </Row>
                  <Row>
                    <Col xs={8}>Username:</Col>
                    <Col xs={16}>
                      {decodeURIComponent(currentUser.username)
                        .split("+")
                        .join(" ")}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>FirstName:</Col>
                    <Col xs={16}>
                      {decodeURIComponent(currentUser.firstName)
                        .split("+")
                        .join(" ")}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>LastName:</Col>
                    <Col xs={16}>
                      {decodeURIComponent(currentUser.lastName)
                        .split("+")
                        .join(" ")}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>Email:</Col>
                    <Col xs={16}>{currentUser.email}</Col>
                  </Row>
                  <Row>
                    <Col xs={8}>Telephone:</Col>
                    <Col xs={16}>{currentUser.tel}</Col>
                  </Row>
                  <Row>
                    <Col xs={8}>Gender:</Col>
                    <Col xs={16}>{currentUser.gender}</Col>
                  </Row>
                  <Row>
                    <Col xs={8}>Birthday:</Col>
                    <Col xs={16}>{currentUser.birthday}</Col>
                  </Row>
                  <Row>
                    <Col xs={8}>Address:</Col>
                    <Col xs={16}>
                      {decodeURIComponent(currentUser.address)
                        .split("+")
                        .join(" ")}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>Province:</Col>
                    <Col xs={16}>{currentUser.province}</Col>
                  </Row>
                  <Row>
                    <Col xs={8}>Role:</Col>
                    <Col xs={16}>{currentUser.role}</Col>
                  </Row>
                  <Row>
                    <Statistic title="Points" value={currentUser.point} />
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card title={"Friends List"} style={{ marginTop: 20 }}>
              <Table
                rowKey="id"
                scroll={{ x: "max-content" }}
                columns={friendsColumns}
                dataSource={currentUser?.friends}
              />
            </Card>
            <Card title={"Cap Transactions"} style={{ marginTop: 20 }}>
              <Table
                rowKey="id"
                scroll={{ x: "max-content" }}
                columns={transactionColumns}
                dataSource={currentUser?.transactions}
              />
            </Card>
            <Modal
              visible={showUserEditModal}
              title={"Edit User Type"}
              onOk={() => handleOk()}
              onCancel={() => handleCancel()}
              footer={[
                <Button key="cancel" onClick={handleCancel}>
                  {"Cancel"}
                </Button>,
                <Button key="accept" type="primary" onClick={handleOk}>
                  {"OK"}
                </Button>,
              ]}
            >
              <Formik
                enableReinitialize
                initialValues={initialValue}
                onSubmit={(data, { setSubmitting }) => {
                  console.log("data", data);
                  if (currentUser) {
                    console.log("debug1");

                    let newData: any = data;
                    //delete newData.password;
                    //delete newData.confirmPassword;
                    editUser(newData, currentUser.id);
                  }
                  console.log("debug2");
                  setSubmitting(false);
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={UserEditSchema}
              >
                {({
                  errors,
                  handleSubmit,
                  submitForm,
                  resetForm,
                  values,
                  setFieldValue,
                }) => {
                  submitMyForm = () => {
                    submitForm();
                  };
                  resetMyForm = () => {
                    resetForm();
                  };
                  return (
                    <Form onFinish={handleSubmit} {...formItemLayout}>
                      <Form.Item label="Image">
                        <AvatarUpload
                          value={values.avatar}
                          onChange={(e: any) => {
                            setFieldValue("avatar", e);
                            console.log("avatar", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={errors.username ? "error" : "success"}
                        help={errors.username}
                        label={"Username"}
                        required
                      >
                        <Field
                          name={"username"}
                          placeholder={"username"}
                          type="input"
                          as={Input}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={errors.password ? "error" : "success"}
                        help={errors.password ? "password-required" : ""}
                        label={"Password"}
                      >
                        <Field
                          name="password"
                          type="password"
                          placeholder={"password"}
                          as={Input}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={
                          errors.confirmPassword ? "error" : "success"
                        }
                        help={errors.confirmPassword}
                        label={"Confirm Password"}
                      >
                        <Field
                          name="confirmPassword"
                          type="password"
                          placeholder={"confirm-password"}
                          as={Input}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={errors.firstName ? "error" : "success"}
                        help={errors.firstName}
                        label={"First Name"}
                        required
                      >
                        <Field
                          name={"firstName"}
                          placeholder={"firstName"}
                          type="input"
                          as={Input}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={errors.lastName ? "error" : "success"}
                        help={errors.lastName}
                        label={"Last Name"}
                        required
                      >
                        <Field
                          name={"lastName"}
                          placeholder={"lastName"}
                          type="input"
                          as={Input}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={errors.email ? "error" : "success"}
                        help={errors.email}
                        label={"Email"}
                        required
                      >
                        <Field
                          name={"email"}
                          placeholder={"email"}
                          type="input"
                          as={Input}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={errors.facebookId ? "error" : "success"}
                        help={errors.facebookId}
                        label={"Facebook ID"}
                        required
                      >
                        <Field
                          name={"facebookId"}
                          placeholder={"facebookId"}
                          type="input"
                          as={Input}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={errors.tel ? "error" : "success"}
                        help={errors.tel}
                        label={"Telephone"}
                        required
                      >
                        <Field
                          name={"tel"}
                          placeholder={"tel"}
                          type="input"
                          as={Input}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={errors.gender ? "error" : "success"}
                        help={errors.gender}
                        label={"Gender"}
                        required
                      >
                        <Select
                          placeholder="Select gender"
                          onChange={(value: any) => {
                            setFieldValue("gender", value);
                          }}
                          value={values.gender}
                        >
                          <Select.Option value="male">Male</Select.Option>
                          <Select.Option value="female">Female</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        validateStatus={errors.dob ? "error" : "success"}
                        help={errors.dob}
                        label={"Birthday"}
                        required
                      >
                        <DatePicker
                          onChange={(date, dateString) => {
                            setFieldValue("dob", dateString);
                          }}
                          value={values.dob ? moment(values.dob) : undefined}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={errors.address ? "error" : "success"}
                        help={errors.address}
                        label={"Address"}
                        required
                      >
                        <Field
                          name={"address"}
                          placeholder={"address"}
                          type="input"
                          as={Input}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={errors.province ? "error" : "success"}
                        help={errors.province}
                        label={"Province"}
                      >
                        <Select
                          placeholder="Select province"
                          onChange={(value: any) => {
                            setFieldValue("province", value);
                          }}
                          value={values.province}
                        >
                          {provinceList.map((item: any) => (
                            <Select.Option value={item} key={item}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        validateStatus={errors.role ? "error" : "success"}
                        help={errors.role}
                        label={"Role"}
                        required
                      >
                        <Select
                          placeholder="Select role"
                          onChange={(value: any) => {
                            setFieldValue("role", value);
                          }}
                          value={values.role}
                        >
                          <Select.Option value="admin">Admin</Select.Option>
                          <Select.Option value="player">Player</Select.Option>
                          <Select.Option value="management'">
                            Management
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Form>
                  );
                }}
              </Formik>
            </Modal>
          </>
        )}
      </StyledInternalContent>
    </Layout>
  );
};

const mapStateToProps = (state: any) => {
  const { userManagement } = state;

  return {
    response: userManagement.response,
    currentUser: userManagement.currentUser,
  };
};

export default connect(mapStateToProps, {
  editUser,
  getUser,
  clearResponse,
})(UserDetailPage);

const provinceList = [
  "กรุงเทพมหานคร",
  "กระบี่",
  "กาญจนบุรี",
  "กาฬสินธุ์",
  "กำแพงเพชร",
  "ขอนแก่น",
  "จันทบุรี",
  "ฉะเชิงเทรา",
  "ชลบุรี",
  "ชัยนาท",
  "ชัยภูมิ",
  "ชุมพร",
  "เชียงราย",
  "เชียงใหม่",
  "ตรัง",
  "ตราด",
  "ตาก",
  "นครนายก",
  "นครปฐม",
  "นครพนม",
  "นครราชสีมา",
  "นครศรีธรรมราช",
  "นครสวรรค์",
  "นนทบุรี",
  "นราธิวาส",
  "น่าน",
  "บึงกาฬ",
  "บุรีรัมย์",
  "ปทุมธานี",
  "ประจวบคีรีขันธ์",
  "ปราจีนบุรี",
  "ปัตตานี",
  "พะเยา",
  "พระนครศรีอยุธยา",
  "พังงา",
  "พัทลุง",
  "พิจิตร",
  "พิษณุโลก",
  "เพชรบุรี",
  "เพชรบูรณ์",
  "แพร่",
  "ภูเก็ต",
  "มหาสารคาม",
  "มุกดาหาร",
  "แม่ฮ่องสอน",
  "ยโสธร",
  "ยะลา",
  "ร้อยเอ็ด",
  "ระนอง",
  "ระยอง",
  "ราชบุรี",
  "ลพบุรี",
  "ลำปาง",
  "ลำพูน",
  "เลย",
  "ศรีสะเกษ",
  "สกลนคร",
  "สงขลา",
  "สตูล",
  "สมุทรปราการ",
  "สมุทรสงคราม",
  "สมุทรสาคร",
  "สระแก้ว",
  "สระบุรี",
  "สิงห์บุรี",
  "สุโขทัย",
  "สุพรรณบุรี",
  "สุราษฎร์ธานี",
  "สุรินทร์",
  "หนองคาย",
  "หนองบัวลำภู",
  "อ่างทอง",
  "อำนาจเจริญ",
  "อุดรธานี",
  "อุตรดิตถ์",
  "อุทัยธานี",
  "อุบลราชธานี",
];
