import * as yup from "yup";

import {
	Button,
	Card,
	Col,
	Descriptions,
	Form,
	Input,
	InputNumber,
	Layout,
	Modal,
	PageHeader,
	Row,
  Checkbox,
	Typography,
} from "antd";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
	clearResponse,
	getVersion,
  getPromotion,
  promotionDataEdit,
	versionDataEdit,
} from "redux/settings/actions";

import { StyledInternalContent } from "components/InternalLayout";
import { StyledInternalHeader } from "components/InternalLayout";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const VersionEditSchema = yup.object({
	obsoleteNotice: yup.string().required(),
});

const defaultInitialValues = {
	allowMinimum: 0,
	obsoleteNotice: "Please update to new version to use",
	forgetPasswordLink: "",
};

const defaultPromotionValues = {
  isAddFriendPromotion: false,
  isCapPromotion: false,
}

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};

const SettingPage: React.FC = ({
	currentVersion,
	response,
	clearResponse,
	getVersion,
  getPromotion,
  currentPromotion,
  promotionResponse,
  promotionDataEdit,
	versionDataEdit,
}: any) => {
	const [initialValue, setInitial] = useState(defaultInitialValues);
	const [promotionValue, setPromotion] = useState(defaultPromotionValues);
	const [showVersionEditModal, setShowVersionEditModal] = useState(false);
	const [showPromotionEditModal, setShowPromotionEditModal] = useState(false);
	useEffect(() => {
    getPromotion();
		getVersion();
	}, []);

  console.log("currentPromotion", currentPromotion)
	console.log("currentVersion", currentVersion);

	useEffect(() => {
		console.log("response", response);

		if (response) {
			console.log("clearingResponse");
			clearResponse();
			setShowVersionEditModal(false);
			getVersion();
		}
	}, [response]);

  useEffect(() => {
		console.log("promotionResponse", promotionResponse);

		if (promotionResponse) {
			console.log("clearingPromotionResponse");
			clearResponse();
			setShowPromotionEditModal(false);
			getPromotion();
		}
	}, [promotionResponse]);

	const handleEditVersion = () => {
		setShowVersionEditModal(true);
	};

  const handleEditPromotion = () => {
		setShowPromotionEditModal(true);
	};

  useEffect(() => {
		if (currentPromotion) {
			let newInitialFormData = { ...promotionValue, ...currentPromotion };
			setPromotion(newInitialFormData);
			resetMyForm();
		} else {
			setPromotion(defaultPromotionValues);
			resetMyForm();
		}
	}, [currentPromotion]);

	useEffect(() => {
		if (currentVersion) {
			let newInitialFormData = { ...initialValue };
			if (currentVersion.allowMinimum && currentVersion.allowMinimum !== 0)
				newInitialFormData.allowMinimum = currentVersion.allowMinimum;
			if (currentVersion.obsoleteNotice && currentVersion.obsoleteNotice !== "")
				newInitialFormData.obsoleteNotice = currentVersion.obsoleteNotice;
			if (
				currentVersion.forgetPasswordLink &&
				currentVersion.forgetPasswordLink !== ""
			)
				newInitialFormData.forgetPasswordLink =
					currentVersion.forgetPasswordLink;

			setInitial(newInitialFormData);
			resetMyForm();
		} else {
			setInitial(defaultInitialValues);
			resetMyForm();
		}
	}, [currentVersion]);

	let submitMyForm = () => {};
	let resetMyForm = () => {};
	const handleOk = () => {
		submitMyForm();
	};

	const handleCancel = () => {
		resetMyForm();
		setShowVersionEditModal(false);
		setShowPromotionEditModal(false);
	};

	return (
		<Layout>
			<StyledInternalHeader>
				<br />
				<PageHeader
					title={"Settings"}
					style={{ paddingBottom: 0 }}
					extra={null}
				></PageHeader>
				<br />
			</StyledInternalHeader>
			<StyledInternalContent>
				<Row style={{ height: "100vh" }}>
					<Card>
						<Descriptions title="Version Property" bordered column={1}>
							<Descriptions.Item label="Minimum Version">
								{currentVersion?.allowMinimum}
							</Descriptions.Item>
							<Descriptions.Item label="Obsolete Notice">
								{currentVersion?.obsoleteNotice}
							</Descriptions.Item>
							<Descriptions.Item label="Forget Password Link">
								{currentVersion?.forgetPasswordLink}
							</Descriptions.Item>
						</Descriptions>
						<Button onClick={() => handleEditVersion()}>Change</Button>

            <Descriptions title="Promotion Property" bordered column={1}>
							<Descriptions.Item label="isAddFriendPromotion">
								{`${currentPromotion?.isAddFriendPromotion}`}
							</Descriptions.Item>
							<Descriptions.Item label="isCapPromotion">
								{`${currentPromotion?.isCapPromotion}`}
							</Descriptions.Item>
						</Descriptions>
						<Button onClick={() => handleEditPromotion()}>Change</Button>
					</Card>
				</Row>
			</StyledInternalContent>

      <Modal
				visible={showPromotionEditModal}
				title={"Edit Promotion Setting"}
				onOk={() => handleOk()}
				onCancel={() => handleCancel()}
				footer={[
					<Button key="cancel" onClick={handleCancel}>
						{"Cancel"}
					</Button>,
					<Button key="accept" type="primary" onClick={handleOk}>
						{"OK"}
					</Button>,
				]}
			>
				<Formik
					enableReinitialize
					initialValues={promotionValue}
					onSubmit={(data, { setSubmitting }) => {
						console.log("data", data);
						promotionDataEdit(data);
						setSubmitting(false);
					}}
				>
					{({
						errors,
						handleSubmit,
						submitForm,
						resetForm,
						values,
						setFieldValue,
					}) => {
						submitMyForm = () => {
							submitForm();
						};
						resetMyForm = () => {
							resetForm();
						};
						return (
							<Form onFinish={handleSubmit} {...formItemLayout}>
								<Form.Item
									label={"isAddFriendPromotion"}
								>
									<Checkbox
										checked={values.isAddFriendPromotion}
										onChange={(e: any) =>
											setFieldValue("isAddFriendPromotion", e.target.checked)
										}
									/>
								</Form.Item>
								<Form.Item
									label={"isCapPromotion"}
								>
									<Checkbox
										checked={values.isCapPromotion}
										onChange={(e: any) =>
											setFieldValue("isCapPromotion", e.target.checked)
										}
									/>
								</Form.Item>
							</Form>
						);
					}}
				</Formik>
			</Modal>

			<Modal
				visible={showVersionEditModal}
				title={"Edit Version Setting"}
				onOk={() => handleOk()}
				onCancel={() => handleCancel()}
				footer={[
					<Button key="cancel" onClick={handleCancel}>
						{"Cancel"}
					</Button>,
					<Button key="accept" type="primary" onClick={handleOk}>
						{"OK"}
					</Button>,
				]}
			>
				<Formik
					enableReinitialize
					initialValues={initialValue}
					onSubmit={(data, { setSubmitting }) => {
						console.log("data", data);
						versionDataEdit(data);
						setSubmitting(false);
					}}
					validateOnChange={false}
					validateOnBlur={false}
					validationSchema={VersionEditSchema}
				>
					{({
						errors,
						handleSubmit,
						submitForm,
						resetForm,
						values,
						setFieldValue,
					}) => {
						submitMyForm = () => {
							submitForm();
						};
						resetMyForm = () => {
							resetForm();
						};
						return (
							<Form onFinish={handleSubmit} {...formItemLayout}>
								<Form.Item
									validateStatus={errors.allowMinimum ? "error" : "success"}
									help={errors.allowMinimum}
									label={"Minimum Version"}
								>
									<InputNumber
										value={values.allowMinimum}
										onChange={(output: any) =>
											setFieldValue("allowMinimum", output)
										}
									/>
								</Form.Item>
								<Form.Item
									validateStatus={errors.obsoleteNotice ? "error" : "success"}
									help={errors.obsoleteNotice}
									label={"Obsolete Notice"}
									required
								>
									<Field name={"obsoleteNotice"} type="input" as={Input} />
								</Form.Item>
								<Form.Item
									validateStatus={
										errors.forgetPasswordLink ? "error" : "success"
									}
									help={errors.forgetPasswordLink}
									label={"Forget Password Link"}
									required
								>
									<Field name={"forgetPasswordLink"} type="input" as={Input} />
								</Form.Item>
							</Form>
						);
					}}
				</Formik>
			</Modal>
		</Layout>
	);
};

const mapStateToProps = (state: any) => {
	const { setting } = state;

	return {
		currentVersion: setting.currentVersion,
		response: setting.response,
    currentPromotion: setting.currentPromotion,
    promotionResponse: setting.promotionResponse,
	};
};

export default connect(mapStateToProps, {
	getVersion,
  getPromotion,
  promotionDataEdit,
	versionDataEdit,
	clearResponse,
})(SettingPage);
