import { Col, Drawer, Layout, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
	StyledContainerLayout,
	StyledContent,
	Transition,
} from "./StyledMainLayout";

import MainDrawerComponent from "./MainDrawerComponent";
import { MainHeaderComponent } from "./MainHeaderComponent";
import { ThemeContext } from "styled-components";

interface Props {
	iconLink?: string;
	customIcon?: any;
	headerExtra?: any;
	children: any;
	navData: any;
	headerNav?: any;
	isDisplayHeaderLeftMenu?: boolean;
	classicSidebar?: boolean;
	headerColor?: string;
	title?: string;
	backUrl?: string;
}

export const MainLayout: React.FC<Props> = (props) => {
	const { headerColor, title, backUrl } = props;
	const [collapsed, setCollapsed] = useState(true);
	const [isMobile, setMobile] = useState(false);
	const [scrollPos, setScrollPos] = useState(0);
	const [show, setShow] = useState(true);
	const themeContext = useContext(ThemeContext);
	const updateWidthAndHeight = () => {
		const w =
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth;
		if (w < 1300) {
			setMobile(true);
		} else {
			//setCollapsed(false);
			setMobile(false);
		}
	};

	useEffect(() => {
		updateWidthAndHeight();
		window.addEventListener("resize", updateWidthAndHeight);
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("resize", updateWidthAndHeight);
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const handleScroll = () => {
		setScrollPos(document.body.getBoundingClientRect().top);
		setShow(document.body.getBoundingClientRect().top > scrollPos - 0);
	};

	const toggleDrawer = () => {
		setCollapsed(!collapsed);
	};
	const closeDrawer = () => {
		setCollapsed(true);
	};
	const openDrawer = () => {
		setCollapsed(false);
	};

	const drawerProps = {
		collapsed: collapsed,
		navData: props.navData,
		isMobile: isMobile,
		closeDrawer: () => closeDrawer(),
		openDrawer: () => openDrawer(),
		toggleDrawer: () => toggleDrawer(),
		backUrl: backUrl,
	};

	return (
		<Layout
			style={{
				minHeight: "100vh",
			}}
		>
			<MainHeaderComponent
				iconLink={props.iconLink}
				customIcon={props.customIcon}
				collapsed={collapsed}
				toggleDrawer={toggleDrawer}
				navData={props.headerNav}
				headerExtra={props.headerExtra}
				isDisplayHeaderLeftMenu={props.isDisplayHeaderLeftMenu}
				headerColor={headerColor}
				title={title}
			/>
			<StyledContainerLayout>
				{/*isMobile && (
					<Affix offsetTop={80} style={{ position: "absolute" }}>
						<Button onClick={() => toggleDrawer()}>
							<Icon
								type={collapsed ? "menu" : "close"}
								style={{
									margin: "0 auto",
									fontSize: 20,
								}}
							/>
						</Button>
					</Affix>
							)*/}
				{!props.classicSidebar || isMobile ? (
					<Drawer
						maskClosable
						closable={false}
						onClose={() => setCollapsed(true)}
						visible={!collapsed}
						placement="left"
						width={250}
						style={{
							padding: 0,
							height: "100vh",
						}}
						bodyStyle={{
							background: themeContext.drawer.backgroundAdminColor,
							padding: 0,
						}}
						drawerStyle={{
							background: themeContext.drawer.backgroundAdminColor,
							padding: 0,
						}}
					>
						<MainDrawerComponent {...drawerProps} />
					</Drawer>
				) : (
					<MainDrawerComponent {...drawerProps} />
				)}
				<Transition>
					<StyledContent className={isMobile ? "close" : "open"}>
						<Row justify="center">
							<Col lg={24} xs={24} style={{ maxWidth: "100vw" }}>
								<div>{props.children}</div>
							</Col>
						</Row>
					</StyledContent>
				</Transition>
			</StyledContainerLayout>
		</Layout>
	);
};
