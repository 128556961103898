import { Alert, Upload, message } from "antd";
import Icon, { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import request from "../../lib/request";

interface Props {
	onChange: any;
	value: string;
}

const UPLOAD_URL = "/upload";

export const AvatarUpload: React.FC<Props> = (props) => {
	const [imageUrl, setImageUrl] = useState<any>();
	const [loading, setLoading] = useState(false);
	const [uploadError, setUploadError] = useState("");

	useEffect(() => {
		setImageUrl(props.value);
	}, [props.value]);
	const beforeUpload = (file: any) => {
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
		if (!isJpgOrPng) {
			message.error("You can only upload JPG/PNG file!");

			setUploadError("You can only upload JPG/PNG file!");
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error("Image must smaller than 2MB!");

			setUploadError("Image must smaller than 2MB!");
		}
		if (isJpgOrPng && isLt2M) setUploadError("");
		return isJpgOrPng && isLt2M;
	};

	const handleAvatarChange = (info: any) => {
		console.log("info", info);
		if (info.file.status === "uploading") {
			setLoading(true);
			return;
		}
		if (info.file.status === "done") {
			setLoading(false);
		}
	};
	const readUploadedFileAsText = (inputFile: any) => {
		const temporaryFileReader = new FileReader();

		return new Promise((resolve, reject) => {
			temporaryFileReader.onerror = () => {
				temporaryFileReader.abort();
				reject(new DOMException("Problem parsing input file."));
			};

			temporaryFileReader.onload = () => {
				resolve(temporaryFileReader.result);
			};
			temporaryFileReader.readAsDataURL(inputFile);
		});
	};
	return (
		<React.Fragment>
			{uploadError !== "" && <Alert message={uploadError} type="error" />}
			<Upload
				name="avatar"
				listType={"picture-card"}
				showUploadList={false}
				beforeUpload={beforeUpload}
				onChange={handleAvatarChange}
				customRequest={async ({ onSuccess, onError, file }) => {
					console.log(1);
					try {
						const res = await request.post(UPLOAD_URL, {
							data: await readUploadedFileAsText(file),
						});
						console.log({
							data: await readUploadedFileAsText(file),
						});
						console.log(
							"🚀 ~ file: AvatarUpload.tsx ~ line 61 ~ customRequest={ ~ res",
							res.request
						);
						console.log("result", res);
						props.onChange(res?.data?.url);
						setImageUrl(res?.data?.url);
						setImageUrl(res);
						onSuccess(res, file);
					} catch (e) {
						console.log(
							"🚀 ~ file: AvatarUpload.tsx ~ line 89 ~ customRequest={ ~ e",
							e
						);
						console.log("error", e);
						onError(e);
					}
				}}
			>
				{props.value ? (
					<div>
						{loading ? (
							<LoadingOutlined />
						) : (
							<img src={props.value} alt="avatar" style={{ width: "100%" }} />
						)}
					</div>
				) : (
					<div>
						{loading ? <LoadingOutlined /> : <PlusOutlined />}
						<div className="ant-upload-text">{"upload"}</div>
					</div>
				)}
			</Upload>
		</React.Fragment>
	);
};
