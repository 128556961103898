const GameTypeActionTypes = {
  GET_GAMETYPE: 'GET_GAMETYPE',
  GET_GAMETYPELIST: 'GET_GAMETYPELIST',
  CREATE_GAMETYPE: 'CREATE_GAMETYPE',
  EDIT_GAMETYPE: 'EDIT_GAMETYPE',
  DELETE_GAMETYPE: 'DELETE_GAMETYPE',
  
  GET_GAMEMODULE: 'GET_GAMEMODULE',
  CREATE_GAMEMODULE: 'CREATE_GAMEMODULE',
  EDIT_GAMEMODULE: 'EDIT_GAMEMODULE',
  DELETE_GAMEMODULE: 'DELETE_GAMEMODULE',
  
  GET_GAMEPAGE: 'GET_GAMEPAGE',
  CREATE_GAMEPAGE: 'CREATE_GAMEPAGE',
  EDIT_GAMEPAGE: 'EDIT_GAMEPAGE',
  DELETE_GAMEPAGE: 'DELETE_GAMEPAGE',
  
  GET_GAMEHINT: 'GET_GAMEHINT',
  CREATE_GAMEHINT: 'CREATE_GAMEHINT',
  EDIT_GAMEHINT: 'EDIT_GAMEHINT',
  DELETE_GAMEHINT: 'DELETE_GAMEHINT',

  GET_GAMEPOINT: 'GET_GAMEPOINT',
  CREATE_GAMEPOINT: 'CREATE_GAMEPOINT',
  EDIT_GAMEPOINT: 'EDIT_GAMEPOINT',
  DELETE_GAMEPOINT: 'DELETE_GAMEPOINT',

  GET_GAMESESSIONLIST: 'GET_GAMESESSIONLIST',
  CREATE_GAMESESSION: 'CREATE_GAMESESSION',
  DELETE_GAMESESSION: 'DELETE_GAMESESSION',
  CLEAR_RESPONSE: 'CLEAR_RESPONSE',
};

export default GameTypeActionTypes;
