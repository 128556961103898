import Request from 'lib/request';
import UserActionTypes from "./types";

export const login = (body: any) => async (dispatch: any) => {
  try {
    const response = await Request.post('/users/login', body)
    const payload = response.data;
    dispatch({ type: UserActionTypes.LOGIN, payload });
  } catch (error) {
    console.log(error);
  }
};


export const logout = () => async (dispatch: any) => {
    console.log('logging Out');
    localStorage.clear();
    dispatch({ type: UserActionTypes.LOGOUT });
};

export const resetPassword = (body: any) => async (dispatch: any) => {
  try {
    const response = await Request.post('/users/resetPassword', body)
    const payload = response.data;
    dispatch({ type: UserActionTypes.RESETPASSWORD, payload });
  } catch (error) {
    console.log(error);
  }
};
export const forgetPassword = (body: any) => async (dispatch: any) => {
  try {
    const response = await Request.post('/users/forgetPassword', body)
    const payload = response.data;
    dispatch({ type: UserActionTypes.FORGET_PASSWORD, payload });
  } catch (error) {
    console.log(error);
  }
}
