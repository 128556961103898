import { Layout } from "antd";
import styled from "styled-components";

export const StyledHeader = styled(Layout)`
	padding-top: 0;
	padding-left: calc(50vw - 580px);
	padding-right: calc(50vw - 580px);
	background-color: #fff;
`;
export const StyledInternalHeader = styled(Layout)`
	padding-top: 0;
	padding-left: calc(50vw - 580px);
	padding-right: calc(50vw - 580px);
	margin-top: 75px;
	background-color: #fff;
`;

export const StyledInternalSubHeader = styled(Layout)`
	padding-top: 0;
	padding-left: calc(50vw - 580px);
	padding-right: calc(50vw - 580px);
	margin-top: 20px;
	background-color: #fff;
`;

export const StyledInternalContent = styled(Layout.Content)`
	padding: 2rem;
	padding-left: calc(50vw - 600px);
	padding-right: calc(50vw - 600px);
	margin-left: 20px;
	margin-right: 20px;
	min-height: "~calc(100% - 72px)";
`;
