import { Button, Layout, PageHeader, Result } from "antd";
import { StyledHeader, StyledInternalContent } from "components/InternalLayout";

import React from "react";
import { useHistory } from "react-router-dom";

interface Props {}

export const PageNotAuthorized: React.FC<Props> = () => {
	let history = useHistory();
	return (
		<Layout>
			<StyledHeader>
				<br />
				<PageHeader
					title={"Not Authorized"}
					style={{ paddingBottom: 0 }}
				></PageHeader>
				<br />
			</StyledHeader>
			<StyledInternalContent>
				<Result
					status="403"
					title="403"
					subTitle="Sorry, you are not authorized to access this page."
					extra={
						<Button type="primary" onClick={() => history.push("/login")}>
							Back to login
						</Button>
					}
				/>
			</StyledInternalContent>
		</Layout>
	);
};
