import "moment/min/locales.min";

import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { RightContainerDiv, StyledHeader } from "./StyledHeaderComponent";

import { Menu } from "antd";
import styled from "styled-components";

interface Props {
	iconLink?: string;
	customIcon?: any;
	headerExtra?: any;
	collapsed: boolean;
	navData?: any;
	toggleDrawer(): any;
	isDisplayHeaderLeftMenu?: boolean;
	headerColor?: string;
	title?: string;
}

export const MainHeaderComponent: React.FC<Props> = (props) => {
	return (
		<StyledHeader headerColor={props.headerColor}>
			<div style={{ display: "flex" }}>
				<Menu mode="horizontal" style={{ background: props.headerColor }}>
					<Menu.Item
						key="1"
						onClick={() => props.toggleDrawer()}
						style={{
							marginLeft: "20px",
							fontSize: 20,
							border: "none",
						}}
					>
						{props.collapsed ? (
							<MenuOutlined
								style={{
									margin: "0 auto",
									fontSize: 20,
									color: props.headerColor ? "#fff" : "rgba(0, 0, 0, 0.65)",
								}}
							/>
						) : (
							<CloseOutlined
								style={{
									margin: "0 auto",
									fontSize: 20,
									color: props.headerColor ? "#fff" : "rgba(0, 0, 0, 0.65)",
								}}
							/>
						)}
					</Menu.Item>
					<Menu.Item key="2">
						<img
							src={
								"https://bucket-development.s3-ap-southeast-1.amazonaws.com/statics/app-icon.png"
							}
							width="45"
							alt=""
							title="schedule-new-S"
						/>
						{"   "}
						Rock Party App Management
						{/*
					<a href="/">
						<img
							src={setting["imgURL"] + "fullsizelogo.png"}
							width="45"
							alt=""
							title="schedule-new-S"
						/>
					</a>*/}
					</Menu.Item>
				</Menu>
			</div>
		</StyledHeader>
	);
};
