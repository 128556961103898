import { Avatar, Menu } from "antd";
import {
	CloseOutlined,
	MenuOutlined,
	PoweroffOutlined,
} from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import {
	StyledLogoContainer,
	StyledMenuContainer,
	StyledSider,
} from "./StyledDrawerComponent";

import { ThemeContext } from "styled-components";
import { connect } from "react-redux";
import { logout } from "redux/user/actions";
import { useHistory } from "react-router-dom";

interface Props {
	collapsed: boolean;
	navData: any[];
	isMobile: boolean;
	closeDrawer: any;
	openDrawer: any;
	toggleDrawer: any;
	backUrl?: string;
	logout: any;
}

const MainDrawerComponent: React.FC<Props> = (props) => {
	let history = useHistory();
	//const { logout } = useLogoutHandler();
	const themeContext = useContext(ThemeContext);
	const [scrollPos, setScrollPos] = useState(0);
	const [show, setShow] = useState(true);

	// useEffect(() => {
	// 	window.addEventListener("scroll", handleScroll);
	// 	return () => {
	// 		window.removeEventListener("scroll", handleScroll);
	// 	}
	// }, []);

	// const handleScroll = () => {
	// 	setScrollPos(document.body.getBoundingClientRect().top);
	// 	setShow(document.body.getBoundingClientRect().top > scrollPos-0);
	// };

	return (
		// <Transition>
		<StyledSider
			className={show ? "active" : "hidden"}
			collapsed={props.collapsed}
			width={256}
			color={themeContext.drawer.backgroundAdminColor}
		>
			{!props.collapsed && (
				<StyledLogoContainer className={"drawer-user-container"}>
					<div style={{ marginTop: "2rem" }}>
						{" "}
						<Avatar
							shape="square"
							src={
								"https://bucket-development.s3-ap-southeast-1.amazonaws.com/statics/app-icon.png"
							}
							size={150}
						/>
					</div>
				</StyledLogoContainer>
			)}
			<StyledMenuContainer color={themeContext.drawer.backgroundAdminColor}>
				{/* <ScrollBar
					options={{
						// Disabled horizontal scrolling, https://github.com/utatti/perfect-scrollbar#options
						suppressScrollX: true
					}}
				> */}
				<Menu
					theme="dark"
					mode="inline"
					className="sider-menu"
					// style={{ height: '100%', borderRight: 0,background: '#27245A' }}
					style={{ background: themeContext.drawer.backgroundAdminColor }}
				>
					{props.collapsed && (
						<Menu.Item
							key={"nav-" + "toggle"}
							onClick={() => {
								props.openDrawer();
							}}
						>
							<MenuOutlined />
							<span>{"Open Menu"}</span>
						</Menu.Item>
					)}
					{props.navData.map((data, index) =>
						!data.subMenu ? (
							<Menu.Item
								key={"nav-" + index}
								onClick={() => {
									if (props.isMobile) props.closeDrawer();
									history.push(data.link);
								}}
							>
								<MenuOutlined />
								<span>{data.title.toUpperCase()}</span>
							</Menu.Item>
						) : (
							<Menu.SubMenu
								key={"subnav-" + index}
								// onTitleClick={() => {
								// 	if (props.isMobile) props.closeDrawer();
								// 	history.push(data.link);
								// }}
								title={
									<span>
										<MenuOutlined />
										<span>{data.title.toUpperCase()} </span>
									</span>
								}
							>
								{data.subMenu &&
									data.subMenu.map((subData: any, subIndex: Number) => (
										<Menu.Item
											key={"nav-" + index + subIndex}
											onClick={() => {
												if (props.isMobile) props.closeDrawer();
												history.push(subData.link);
											}}
										>
											<MenuOutlined />
											<span>{subData.title.toUpperCase()}</span>
										</Menu.Item>
									))}
							</Menu.SubMenu>
						)
					)}
					<Menu.Item
						onClick={() => {
							console.log("Logout clicked");
							props.logout();
						}}
					>
						<PoweroffOutlined />
						<span>{"sign-out".toUpperCase()}</span>
					</Menu.Item>
				</Menu>
				{/* </ScrollBar> */}
			</StyledMenuContainer>
		</StyledSider>
		// </Transition>
	);
};

const mapStateToProps = (state: any) => {
	const { setting } = state;

	return {
		response: setting.response,
	};
};

export default connect(mapStateToProps, {
	logout,
})(MainDrawerComponent);
