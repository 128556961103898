const VideoManagementActionTypes = {
  GET_VIDEOSLIST: 'GET_VIDEOSLIST',
  GET_VIDEO: 'GET_VIDEO',
  GET_VIDEO_REDEEM: 'GET_VIDEO_REDEEM',
  CREATE_VIDEO: 'CREATE_VIDEO',
  EDIT_VIDEO: 'EDIT_VIDEO',
  DELETE_VIDEO: 'DELETE_VIDEO',
  CLEAR_RESPONSE: 'CLEAR_RESPONSE',
};

export default VideoManagementActionTypes;
