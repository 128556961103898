import VideoManagementActionTypes from './types';

const INITIAL_STATE = {
  response:null,
  currentVideo:null,
  videoList:null,
  videoRedeem:null
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case VideoManagementActionTypes.GET_VIDEO:
      return { ...state, currentVideo: action.payload };
    case VideoManagementActionTypes.GET_VIDEO_REDEEM:
      return { ...state, videoRedeem: action.payload };
    case VideoManagementActionTypes.GET_VIDEOSLIST:
      return { ...state, videoList: action.payload };
    case VideoManagementActionTypes.CREATE_VIDEO:
      return { ...state, response: action.payload };
    case VideoManagementActionTypes.EDIT_VIDEO:
      return { ...state, response: action.payload };
    case VideoManagementActionTypes.DELETE_VIDEO:
      return { ...state, response: action.payload };
      
    case VideoManagementActionTypes.CLEAR_RESPONSE:
      return { ...state, response: action.payload };


    default:
      return state;
  }
};
