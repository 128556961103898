import { ButtonTheme } from "../themes/types";

const buttonTheme: ButtonTheme = {
  primaryColor: '#0063C3',
  loginColor: '#0063C3',
  defaultColor: 'transparent',
  borderColor: '#0063C3',
}

export { buttonTheme };
