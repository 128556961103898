import { DrawerTheme } from "../themes/types";

const drawerTheme: DrawerTheme = {
    backgroundColor: '#0063C3',
    backgroundAdminColor: '#0063C3',
    backgroundParentColor: '#0063C3',
    backgroundStudentColor: '#0063C3',
}

export { drawerTheme };
