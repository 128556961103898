import * as yup from "yup";

import {
  Avatar,
  Button,
  Card,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  Modal,
  PageHeader,
  Popconfirm,
  Select,
  Table,
} from "antd";
import {
  EllipsisOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  StyledInternalContent,
  StyledInternalHeader,
} from "../components/InternalLayout";
import {
  clearResponse,
  deleteUser,
  editUser,
  getUsersList,
  registerUser,
} from "redux/userManagement/actions";

import { AvatarUpload } from "../components/upload/AvatarUpload";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";

interface Props {}

const UserEditSchema = yup.object({
  //firstName: yup.string().required(),
  //username: yup.string().required(),
  password: yup
    .string()
    .oneOf([yup.ref("confirmPassword")], "Passwords must match"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const defaultInitialValues = {
  username: "",
  password: "",
  confirmPassword: "",
  avatar: "",
  firstName: "",
  lastName: "",
  facebookId: "",
  email: "",
  tel: "",
  gender: "",
  dob: "",
  address: "",
  province: "",
  role: "",
};

const UserManagementPage: React.FC<Props> = ({
  response,
  userList,
  getUsersList,
  registerUser,
  editUser,
  deleteUser,
  clearResponse,
}: any) => {
  let history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [initialValue, setInitial] = useState(defaultInitialValues);
  const [showUserEditModal, setShowUserEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();

  const onPageChange = (input: any) => {
    console.log("pageChange ", input);
    setCurrentPage(input.current);
  };
  useEffect(() => {
    console.log("getting User List", (currentPage - 1) * 50);
    getUsersList(50, (currentPage - 1) * 50);
  }, [currentPage]);

  useEffect(() => {
    console.log("response", response);

    if (response) {
      console.log("clearingResponse");
      clearResponse();
      setShowUserEditModal(false);
      getUsersList(50, (currentPage - 1) * 50);
    }
  }, [response]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const handleAddUser = () => {
    setSelectedUser(null);
    setShowUserEditModal(true);
  };

  const handleEditUser = (id: string) => {
    setSelectedUser(userList.find((user: any) => user.id === id));
    //bannersData.banners.find((banner: any) => banner.id === id)
    setShowUserEditModal(true);
  };

  useEffect(() => {
    if (selectedUser) {
      let newInitialFormData = { ...initialValue };
      if (selectedUser.username && selectedUser.username !== "")
        newInitialFormData.username = selectedUser.username;
      if (selectedUser.avatar && selectedUser.avatar !== "")
        newInitialFormData.avatar = selectedUser.avatar;
      if (selectedUser.firstName && selectedUser.firstName !== "")
        newInitialFormData.firstName = selectedUser.firstName;
      if (selectedUser.lastName && selectedUser.lastName !== "")
        newInitialFormData.lastName = selectedUser.lastName;
      if (selectedUser.email && selectedUser.email !== "")
        newInitialFormData.email = selectedUser.email;
      if (selectedUser.facebookId && selectedUser.facebookId !== "")
        newInitialFormData.facebookId = selectedUser.facebookId;
      if (selectedUser.tel && selectedUser.tel !== "")
        newInitialFormData.tel = selectedUser.tel;
      if (selectedUser.gender && selectedUser.gender !== "")
        newInitialFormData.gender = selectedUser.gender;
      if (selectedUser.dob && selectedUser.dob !== "")
        newInitialFormData.dob = selectedUser.dob;
      if (selectedUser.address && selectedUser.address !== "")
        newInitialFormData.address = selectedUser.address;
      if (selectedUser.province && selectedUser.province !== "")
        newInitialFormData.province = selectedUser.province;
      if (selectedUser.role && selectedUser.role !== "")
        newInitialFormData.role = selectedUser.role;

      setInitial(newInitialFormData);
      resetMyForm();
    } else {
      setInitial(defaultInitialValues);
      resetMyForm();
    }
  }, [selectedUser]);

  const handleDeleteUser = (id: string) => {
    deleteUser(id);
  };

  const UserMenu = (id: string) => (
    <Menu>
      <Menu.Item onClick={() => handleEditUser(id)}>{"edit"}</Menu.Item>
      <Menu.Item>
        <Popconfirm
          title="Are you sure you want to delete this gametype?."
          onConfirm={() => handleDeleteUser(id)}
          okText="Yes"
          cancelText="No"
        >
          <a>{"Delete"}</a>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
  const columns = [
    {
      title: "Image",
      dataIndex: "avatar",
      key: "avatar",
      render: (text: string, record: any) => (
        <Avatar shape="square" size={64} icon={<UserOutlined />} src={text} />
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text: string, record: any) => (
        <a
          onClick={(e: any) => {
            e?.domEvent?.stopPropagation();
            history.push("/userDetail/" + record.id);
          }}
        >
          {decodeURIComponent(text)}
        </a>
      ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text: string, record: any) => (
        <a
          onClick={(e: any) => {
            e?.domEvent?.stopPropagation();
            history.push("/userDetail/" + record.id);
          }}
        >
          {decodeURIComponent(text)}
        </a>
      ),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (text: string, record: any) => (
        <a
          onClick={(e: any) => {
            e?.domEvent?.stopPropagation();
            history.push("/userDetail/" + record.id);
          }}
        >
          {decodeURIComponent(text)}
        </a>
      ),
    },
    {
      title: "Points",
      dataIndex: "point",
      key: "point",
    },
    {
      title: "Facebook",
      dataIndex: "facebookId",
      key: "facebookId",
      render: (text: string, record: any) => (
        <a
          onClick={(e: any) => {
            e?.domEvent?.stopPropagation();
            window.open("https://www.facebook.com/" + text, "_blank");
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Telephone",
      dataIndex: "tel",
      key: "tel",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
      key: "dob",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text: string, record: any) => (
        <span>{decodeURIComponent(text).split("+").join(" ")}</span>
      ),
    },
    {
      title: "Province",
      dataIndex: "province",
      key: "province",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (text: string, record: any) => (
        <Dropdown key="more" overlay={UserMenu(record.id)}>
          <Button
            style={{
              border: "none",
              padding: 0,
            }}
          >
            <EllipsisOutlined
              style={{
                fontSize: 20,
                verticalAlign: "top",
              }}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  let submitMyForm = () => {};
  let resetMyForm = () => {};
  const handleOk = () => {
    submitMyForm();
  };

  const handleCancel = () => {
    resetMyForm();
    setShowUserEditModal(false);
  };

  return (
    <Layout>
      <StyledInternalHeader>
        <br />
        <PageHeader
          title={"User Management"}
          style={{ paddingBottom: 0 }}
          extra={[
            <Button
              type="primary"
              key={"create"}
              onClick={() => handleAddUser()}
            >
              {"Create new user"}
            </Button>,
          ]}
        ></PageHeader>
        <br />
      </StyledInternalHeader>
      <StyledInternalContent>
        <Card>
          <Table
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={columns}
            // expandedRowRender={(record) => (
            // 	<img alt="example" src={record.img} style={{ width: "100%" }} />
            // )}
            dataSource={userList}
            pagination={{ pageSize: 50, simple: true, total: 80000 }}
            onChange={onPageChange}
          />
        </Card>
        <Modal
          visible={showUserEditModal}
          title={"Edit User Type"}
          onOk={() => handleOk()}
          onCancel={() => handleCancel()}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              {"Cancel"}
            </Button>,
            <Button key="accept" type="primary" onClick={handleOk}>
              {"OK"}
            </Button>,
          ]}
        >
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={(data, { setSubmitting }) => {
              console.log("data", data);
              if (selectedUser) {
                let newData: any = data;
                //delete newData.password;
                //delete newData.confirmPassword;
                editUser(newData, selectedUser.id);
              } else {
                registerUser(data);
              }
              setSubmitting(false);
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={UserEditSchema}
          >
            {({
              errors,
              handleSubmit,
              submitForm,
              resetForm,
              values,
              setFieldValue,
            }) => {
              submitMyForm = () => {
                submitForm();
              };
              resetMyForm = () => {
                resetForm();
              };
              return (
                <Form onFinish={handleSubmit} {...formItemLayout}>
                  <Form.Item label="Image">
                    <AvatarUpload
                      value={values.avatar}
                      onChange={(e: any) => {
                        setFieldValue("avatar", e);
                        console.log("avatar", e);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.username ? "error" : "success"}
                    help={errors.username}
                    label={"Username"}
                    required
                  >
                    <Field
                      name={"username"}
                      placeholder={"username"}
                      type="input"
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.password ? "error" : "success"}
                    help={errors.password ? "password-required" : ""}
                    label={"Password"}
                  >
                    <Field
                      name="password"
                      type="password"
                      placeholder={"password"}
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={
                      errors.confirmPassword ? "error" : "success"
                    }
                    help={errors.confirmPassword}
                    label={"Confirm Password"}
                  >
                    <Field
                      name="confirmPassword"
                      type="password"
                      placeholder={"confirm-password"}
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.firstName ? "error" : "success"}
                    help={errors.firstName}
                    label={"First Name"}
                    required
                  >
                    <Field
                      name={"firstName"}
                      placeholder={"firstName"}
                      type="input"
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.lastName ? "error" : "success"}
                    help={errors.lastName}
                    label={"Last Name"}
                    required
                  >
                    <Field
                      name={"lastName"}
                      placeholder={"lastName"}
                      type="input"
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.email ? "error" : "success"}
                    help={errors.email}
                    label={"Email"}
                    required
                  >
                    <Field
                      name={"email"}
                      placeholder={"email"}
                      type="input"
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.facebookId ? "error" : "success"}
                    help={errors.facebookId}
                    label={"Facebook ID"}
                    required
                  >
                    <Field
                      name={"facebookId"}
                      placeholder={"facebookId"}
                      type="input"
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.tel ? "error" : "success"}
                    help={errors.tel}
                    label={"Telephone"}
                    required
                  >
                    <Field
                      name={"tel"}
                      placeholder={"tel"}
                      type="input"
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.gender ? "error" : "success"}
                    help={errors.gender}
                    label={"Gender"}
                    required
                  >
                    <Select
                      placeholder="Select gender"
                      onChange={(value: any) => {
                        setFieldValue("gender", value);
                      }}
                      value={values.gender}
                    >
                      <Select.Option value="male">Male</Select.Option>
                      <Select.Option value="female">Female</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.dob ? "error" : "success"}
                    help={errors.dob}
                    label={"Birthday"}
                    required
                  >
                    <DatePicker
                      onChange={(date, dateString) => {
                        setFieldValue("dob", dateString);
                      }}
                      value={values.dob ? moment(values.dob) : undefined}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.address ? "error" : "success"}
                    help={errors.address}
                    label={"Address"}
                    required
                  >
                    <Field
                      name={"address"}
                      placeholder={"address"}
                      type="input"
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.province ? "error" : "success"}
                    help={errors.province}
                    label={"Province"}
                  >
                    <Select
                      placeholder="Select province"
                      onChange={(value: any) => {
                        setFieldValue("province", value);
                      }}
                      value={values.province}
                    >
                      {provinceList.map((item: any) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.role ? "error" : "success"}
                    help={errors.role}
                    label={"Role"}
                    required
                  >
                    <Select
                      placeholder="Select role"
                      onChange={(value: any) => {
                        setFieldValue("role", value);
                      }}
                      value={values.role}
                    >
                      <Select.Option value="admin">Admin</Select.Option>
                      <Select.Option value="player">Player</Select.Option>
                      <Select.Option value="management'">
                        Management
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </StyledInternalContent>
    </Layout>
  );
};

const mapStateToProps = (state: any) => {
  const { userManagement } = state;

  return {
    response: userManagement.response,
    userList: userManagement.userList,
  };
};

export default connect(mapStateToProps, {
  getUsersList,
  registerUser,
  editUser,
  deleteUser,
  clearResponse,
})(UserManagementPage);

const provinceList = [
  "กรุงเทพมหานคร",
  "กระบี่",
  "กาญจนบุรี",
  "กาฬสินธุ์",
  "กำแพงเพชร",
  "ขอนแก่น",
  "จันทบุรี",
  "ฉะเชิงเทรา",
  "ชลบุรี",
  "ชัยนาท",
  "ชัยภูมิ",
  "ชุมพร",
  "เชียงราย",
  "เชียงใหม่",
  "ตรัง",
  "ตราด",
  "ตาก",
  "นครนายก",
  "นครปฐม",
  "นครพนม",
  "นครราชสีมา",
  "นครศรีธรรมราช",
  "นครสวรรค์",
  "นนทบุรี",
  "นราธิวาส",
  "น่าน",
  "บึงกาฬ",
  "บุรีรัมย์",
  "ปทุมธานี",
  "ประจวบคีรีขันธ์",
  "ปราจีนบุรี",
  "ปัตตานี",
  "พะเยา",
  "พระนครศรีอยุธยา",
  "พังงา",
  "พัทลุง",
  "พิจิตร",
  "พิษณุโลก",
  "เพชรบุรี",
  "เพชรบูรณ์",
  "แพร่",
  "ภูเก็ต",
  "มหาสารคาม",
  "มุกดาหาร",
  "แม่ฮ่องสอน",
  "ยโสธร",
  "ยะลา",
  "ร้อยเอ็ด",
  "ระนอง",
  "ระยอง",
  "ราชบุรี",
  "ลพบุรี",
  "ลำปาง",
  "ลำพูน",
  "เลย",
  "ศรีสะเกษ",
  "สกลนคร",
  "สงขลา",
  "สตูล",
  "สมุทรปราการ",
  "สมุทรสงคราม",
  "สมุทรสาคร",
  "สระแก้ว",
  "สระบุรี",
  "สิงห์บุรี",
  "สุโขทัย",
  "สุพรรณบุรี",
  "สุราษฎร์ธานี",
  "สุรินทร์",
  "หนองคาย",
  "หนองบัวลำภู",
  "อ่างทอง",
  "อำนาจเจริญ",
  "อุดรธานี",
  "อุตรดิตถ์",
  "อุทัยธานี",
  "อุบลราชธานี",
];
