import * as yup from "yup";

import {
	Avatar,
	Button,
	Card,
	Dropdown,
	Form,
	Input,
	Layout,
	Menu,
	Modal,
	PageHeader,
	Popconfirm,
	Table,
} from "antd";
import {
	EllipsisOutlined,
	SolutionOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
	clearResponse,
	createGameType,
	deleteGameType,
	editGameType,
	getGameTypeList,
} from "redux/gameType/actions";

import { AvatarUpload } from "components/upload/AvatarUpload";
import { StyledInternalContent } from "../components/InternalLayout";
import { StyledInternalHeader } from "../components/InternalLayout";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

interface Props {}

const GameTypeEditSchema = yup.object({
	name: yup.string().required(),
});

const defaultInitialValues = {
	name: "",
	avatar: "",
	displayName: "",
};

const GameTypeManagementPage: React.FC<Props> = ({
	response,
	gameTypeList,
	getGameTypeList,
	createGameType,
	editGameType,
	deleteGameType,
	clearResponse,
}: any) => {
	let history = useHistory();
	const [initialValue, setInitial] = useState(defaultInitialValues);
	const [showGameTypeEditModal, setShowGameTypeEditModal] = useState(false);
	const [selectedGameType, setSelectedGameType] = useState<any>();

	useEffect(() => {
		console.log("getting Game Type List");
		getGameTypeList(50, 0);
	}, []);

	useEffect(() => {
		console.log("response", response);

		if (response) {
			console.log("clearingResponse");
			clearResponse();
			setShowGameTypeEditModal(false);
			getGameTypeList(10, 0);
		}
	}, [response]);

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	};

	const handleAddGameType = () => {
		setSelectedGameType(null);
		setShowGameTypeEditModal(true);
	};

	const handleEditGameType = (id: string) => {
		console.log("id", id, gameTypeList);
		setSelectedGameType(gameTypeList.find((gt: any) => gt.id === id));
		setShowGameTypeEditModal(true);
	};

	useEffect(() => {
		if (selectedGameType) {
			let newInitialFormData = { ...initialValue };
			if (selectedGameType.name && selectedGameType.name !== "")
				newInitialFormData.name = selectedGameType.name;

			setInitial(newInitialFormData);
			resetMyForm();
		} else {
			setInitial(defaultInitialValues);
			resetMyForm();
		}
	}, [selectedGameType]);

	const handleDeleteGameType = (id: string) => {
		deleteGameType(id);
	};

	const gameTypeMenu = (id: string) => (
		<Menu>
			<Menu.Item onClick={() => handleEditGameType(id)}>{"Edit"}</Menu.Item>
			<Menu.Item>
				<Popconfirm
					title="Are you sure you want to delete this gametype?."
					onConfirm={() => handleDeleteGameType(id)}
					okText="Yes"
					cancelText="No"
				>
					<a>{"Delete"}</a>
				</Popconfirm>
			</Menu.Item>
		</Menu>
	);
	const columns = [
		{
			title: "Image",
			dataIndex: "avatar",
			key: "avatar",
			render: (text: string, record: any) => (
				<Avatar shape="square" size={64} icon={<UserOutlined />} src={text} />
			),
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text: string, record: any) => (
				<a
					onClick={(e: any) => {
						e?.domEvent?.stopPropagation();
						history.push("/gameTypeDetail/" + record.id);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: "Display name",
			dataIndex: "displayName",
			key: "displayName",
			render: (text: string, record: any) => (
				<a
					onClick={(e: any) => {
						e?.domEvent?.stopPropagation();
						history.push("/gameTypeDetail/" + record.id);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (text: string, record: any) => (
				<Dropdown key="more" overlay={gameTypeMenu(record.id)}>
					<Button
						style={{
							border: "none",
							padding: 0,
						}}
					>
						<EllipsisOutlined
							style={{
								fontSize: 20,
								verticalAlign: "top",
							}}
						/>
					</Button>
				</Dropdown>
			),
		},
	];

	let submitMyForm = () => {};
	let resetMyForm = () => {};
	const handleOk = () => {
		submitMyForm();
	};

	const handleCancel = () => {
		resetMyForm();
		setShowGameTypeEditModal(false);
	};

	return (
		<Layout>
			<StyledInternalHeader>
				<br />
				<PageHeader
					title={"Game Type Management"}
					style={{ paddingBottom: 0 }}
					extra={[
						<Button
							type="primary"
							key={"create"}
							onClick={() => handleAddGameType()}
						>
							{"Create new gametype"}
						</Button>,
					]}
				></PageHeader>
				<br />
			</StyledInternalHeader>
			<StyledInternalContent>
				<Card>
					<Table
						rowKey="id"
						scroll={{ x: "max-content" }}
						columns={columns}
						dataSource={gameTypeList}
					/>
				</Card>
				<Modal
					visible={showGameTypeEditModal}
					title={"Edit Game Type"}
					onOk={() => handleOk()}
					onCancel={() => handleCancel()}
					footer={[
						<Button key="cancel" onClick={handleCancel}>
							{"Cancel"}
						</Button>,
						<Button key="accept" type="primary" onClick={handleOk}>
							{"OK"}
						</Button>,
					]}
				>
					<Formik
						enableReinitialize
						initialValues={initialValue}
						onSubmit={(data, { setSubmitting }) => {
							console.log("data", selectedGameType, data);
							if (selectedGameType) {
								editGameType(data, selectedGameType.id);
							} else {
								createGameType(data);
							}
							setSubmitting(false);
						}}
						validateOnChange={false}
						validateOnBlur={false}
						validationSchema={GameTypeEditSchema}
					>
						{({
							errors,
							handleSubmit,
							submitForm,
							resetForm,
							values,
							setFieldValue,
						}) => {
							submitMyForm = () => {
								submitForm();
							};
							resetMyForm = () => {
								resetForm();
							};
							return (
								<Form onFinish={handleSubmit} {...formItemLayout}>
									<Form.Item label="Image">
										<AvatarUpload
											value={values.avatar}
											onChange={(e: any) => setFieldValue("avatar", e)}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.name ? "error" : "success"}
										help={errors.name}
										label={"Name"}
										required
									>
										<Field
											name={"name"}
											placeholder={"name"}
											type="input"
											as={Input}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.displayName ? "error" : "success"}
										help={errors.displayName}
										label={"Display Name"}
										required
									>
										<Field
											name={"displayName"}
											placeholder={"displayName"}
											type="input"
											as={Input}
										/>
									</Form.Item>
								</Form>
							);
						}}
					</Formik>
				</Modal>
			</StyledInternalContent>
		</Layout>
	);
};

const mapStateToProps = (state: any) => {
	const { gameType } = state;

	return {
		response: gameType.response,
		gameTypeList: gameType.gameTypeList,
	};
};

export default connect(mapStateToProps, {
	getGameTypeList,
	createGameType,
	editGameType,
	deleteGameType,
	clearResponse,
})(GameTypeManagementPage);
