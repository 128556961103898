import CapManagementActionTypes from "./types";
import Request from 'lib/request';

export const getCapsList = (limit:number,offset:number) => async (dispatch: any) => {
  try {
    console.log('getting getCapsList')
    const response = await Request.get('/admin/caps?offset='+offset+'&limit='+limit)
    const payload = response.data;
    dispatch({ type: CapManagementActionTypes.GET_CAPSLIST, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};

export const addCap = (body: any) => async (dispatch: any) => {
  try {
    console.log('addCap',body)
    const response = await Request.post('/admin/caps/', body)
    const payload = response.data;
    dispatch({ type: CapManagementActionTypes.ADD_CAP, payload });
  } catch (error) {
    console.log(error);
  }
};

export const uploadCapCSV = (body: any,id:string) => async (dispatch: any) => {
  try {
    console.log('uploadCapCSV')
    const response = await Request.put('/admin/caps/csv', body)
    const payload = response.data;
    dispatch({ type: CapManagementActionTypes.UPLOAD_CAP_CSV, payload });
  } catch (error) {
    console.log(error);
  }
};

export const deleteCap = (id:string) => async (dispatch: any) => {
  try {
    console.log('deleting ',id);
    const response = await Request.delete('/admin/caps/'+id)
    const payload = response.data;
    dispatch({ type: CapManagementActionTypes.DELETE_CAP, payload });
  } catch (error) {
    console.log(error);
  }
};

export const clearResponse = () => async (dispatch: any) => {
  const payload = null;
  dispatch({ type: CapManagementActionTypes.CLEAR_RESPONSE, payload });

};
