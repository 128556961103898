import { BrowserRouter, Route } from "react-router-dom";

import CapManagementPage from "pages/CapManagementPage";
import DashboardPage from "./pages/DashboardPage";
import DeleteDataPage from "./pages/DeleteDataPage";
import ForgetPasswordPage from "pages/ForgetPasswordPage";
import GameModuleDetailPage from "./pages/GameModuleDetailPage";
import GamePageDetailPage from "./pages/GamePageDetailPage";
import GameSessionManagementPage from "pages/GameSessionManagementPage";
import GameTypeDetailPage from "./pages/GameTypeDetailPage";
import GameTypeManagementPage from "./pages/GameTypeManagementPage";
import LoginPage from "./pages/LoginPage";
import LotteryManagementPage from "pages/LotteryManagementPage";
import { MainLayout } from "./components/mainLayout/MainLayout";
import { NavigationData } from "./RoutesMenuItem";
import { PageNotAuthorized } from "pages/PageNotAuthorized";
import { PageNotFound } from "pages/PageNotFound";
import React from "react";
import ResetPasswordPage from "pages/ResetPasswordPage";
import RewardDetailPage from "pages/RewardDetailPage";
import RewardManagementPage from "pages/RewardManagementPage";
import SettingPage from "pages/SettingPage";
import UserDetailPage from "./pages/UserDetailPage";
import UserManagementPage from "./pages/UserManagementPage";
import VideoManagementPage from "pages/VideoManagementPage";
import RedirectPage from "pages/RedirectPage";

const MainLayoutProps = {
	navData: NavigationData,
	classicSidebar: false,
	// headerColor: '#0063C3',
	title: "",
	backUrl: "/landing",
};

export const Routes: React.FC = () => {
	return (
		<div>
			<BrowserRouter>
				<Route path="/" exact component={LoginPage}></Route>
				<Route
					path="/dashboard"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<DashboardPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/user-management"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<UserManagementPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/userDetail/:userID"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<UserDetailPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/session-management"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<GameSessionManagementPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/game-management"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<GameTypeManagementPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/cap-management"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<CapManagementPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/gameTypeDetail/:gameTypeID"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<GameTypeDetailPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/gameModuleDetail/:gameModuleID"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<GameModuleDetailPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/gamePageDetail/:gamePageID"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<GamePageDetailPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/video-management"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<VideoManagementPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/reward-management"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<RewardManagementPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/lottery-management"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<LotteryManagementPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/rewardDetail/:rewardID"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<RewardDetailPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/settings/"
					exact
					render={() => (
						<MainLayout {...MainLayoutProps}>
							<SettingPage />
						</MainLayout>
					)}
				></Route>
				<Route
					path="/resetPassword/"
					exact
					render={() => <ResetPasswordPage />}
				></Route>
				<Route
					path="/forgetPassword/"
					exact
					render={() => <ForgetPasswordPage />}
				></Route>
				<Route
					path="/delete-data"
					exact
					render={() => <DeleteDataPage />}
				></Route>
				<Route
					exact={true}
					path="/notAuthorized"
					render={() => <PageNotAuthorized />}
				/>
        <Route
					exact
					path="/applink/:somthing"
					render={() => <RedirectPage />}
				/>
			</BrowserRouter>
		</div>
	);
};

export default Routes;
