import Request from 'lib/request';
import SettingsActionTypes from './types';

export const getVersion = () => async (dispatch: any) => {
  try {
    console.log('getting version')
    const response = await Request.get('/versions/')
    const payload = response.data;
    dispatch({ type: SettingsActionTypes.GET_VERSION, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};


export const versionDataEdit = (body: any) => async (dispatch: any) => {
  try {
    const response = await Request.patch('/versions/1', body)
    const payload = response.data;
    dispatch({ type: SettingsActionTypes.EDIT_VERSION, payload });
  } catch (error) {
    console.log(error);
  }
};

export const getPromotion = () => async (dispatch: any) => {
  try {
    console.log('getting promotion')
    const response = await Request.get('/promotions/')
    const payload = response.data;
    dispatch({ type: SettingsActionTypes.GET_PROMOTION, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};


export const promotionDataEdit = (body: any) => async (dispatch: any) => {
  try {
    const response = await Request.patch('/promotions/1' , body)
    const payload = response.data;
    dispatch({ type: SettingsActionTypes.EDIT_PROMOTION, payload });
  } catch (error) {
    console.log(error);
  }
};


export const getCapsCount = () => async (dispatch: any) => {
  try {
    console.log('getting caps total')
    const response = await Request.get('/admin/caps/count')
    const payload = response.data;
    dispatch({ type: SettingsActionTypes.GET_CAPS_COUNT, payload });
    console.log('caps total payload',payload)
  } catch (error) {
    console.log(error);
  }
};
export const getRedeemedCapsCount = () => async (dispatch: any) => {
  try {
    console.log('getting redeemed caps total')
    const response = await Request.get('/admin/caps/redeem-count')
    const payload = response.data;
    dispatch({ type: SettingsActionTypes.GET_REDEEMED_CAPS_COUNT, payload });
    console.log('redeemed caps total payload',payload)
  } catch (error) {
    console.log('redeemed caps total error',error)
  }
};
export const getUsersCount = () => async (dispatch: any) => {
  try {
    console.log('getting users total')
    const response = await Request.get('/users/count')
    const payload = response.data;
    dispatch({ type: SettingsActionTypes.GET_USERS_COUNT, payload });
    console.log('users total payload',payload)
  } catch (error) {
    console.log('users total error',error)
  }
};
export const getSessionsCount = () => async (dispatch: any) => {
  try {
    console.log('getting sessions total')
    const response = await Request.get('/admin/game-sessions/count')
    const payload = response.data;
    dispatch({ type: SettingsActionTypes.GET_SESSIONS_COUNT, payload });
    console.log('sessions total payload',payload)
  } catch (error) {
    console.log('sessions total error',error)
  }
};


export const clearResponse = () => async (dispatch: any) => {
  const payload = null;
  dispatch({ type: SettingsActionTypes.CLEAR_RESPONSE, payload });

};
