import capManagementReducer from 'redux/capManagement/reducer';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import gameTypeReducer from 'redux/gameType/reducer';
import rewardManagementReducer from 'redux/rewardManagement/reducer';
import userManagementReducer from 'redux/userManagement/reducer';
import userReducer from 'redux/user/reducer';
import settingReducer from 'redux/settings/reducer';
import videoManagementReducer from 'redux/videoManagement/reducer';

const reducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    gameType: gameTypeReducer,
    setting: settingReducer,
    userManagement: userManagementReducer,
    capManagement: capManagementReducer,
    videoManagement: videoManagementReducer,
    rewardManagement: rewardManagementReducer,
  });

export default reducer;
