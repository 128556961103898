import { GeneralTheme } from '../themes/types';
import { buttonTheme } from './button'

const generalTheme: GeneralTheme = {
    primaryColor: '#0063C3' ,
    secondaryColor: 'black' ,
    backgroundColor: 'black',
    fontColor: 'white',
    button: buttonTheme,
}

export { generalTheme };
