import * as yup from "yup";

import {
	Button,
	Card,
	Dropdown,
	Form,
	Input,
	Layout,
	Menu,
	Modal,
	PageHeader,
	Popconfirm,
	Table,
} from "antd";
import { EllipsisOutlined, SolutionOutlined } from "@ant-design/icons";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
	clearResponse,
	createGameSession,
	deleteGameSession,
	getGameSessionsList,
} from "redux/gameType/actions";

import { StyledInternalContent } from "../components/InternalLayout";
import { StyledInternalHeader } from "../components/InternalLayout";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

interface Props {}

const GameSessionManagementPage: React.FC<Props> = ({
	response,
	gameSessionsList,
	getGameSessionsList,
	createGameSession,
	deleteGameSession,
	clearResponse,
}: any) => {
	let history = useHistory();	
	const [currentPage, setCurrentPage] = useState(0);

	const onPageChange = (input: any) => {
		console.log("pageChange ", input);
		setCurrentPage(input.current);
	};
	useEffect(() => {
		console.log("getting Game Session List", (currentPage-1) * 50);
		getGameSessionsList(50, (currentPage-1) * 50);
	}, [currentPage]);

	useEffect(() => {
		console.log("response", response);

		if (response) {
			console.log("clearingResponse");
			clearResponse();
			getGameSessionsList(50, (currentPage-1) * 50);
		}
	}, [response]);

	const handleAddGameSession = () => {
		createGameSession({});
	};
	const handleDeleteGameSession = (id: string) => {
		deleteGameSession(id);
	};

	const gameSessionMenu = (id: string) => (
		<Menu>
			<Menu.Item>
				<Popconfirm
					title="Are you sure you want to delete this game session?."
					onConfirm={() => handleDeleteGameSession(id)}
					okText="Yes"
					cancelText="No"
				>
					<a>{"Delete"}</a>
				</Popconfirm>
			</Menu.Item>
		</Menu>
	);

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Code",
			dataIndex: "code",
			key: "code",
		},
		{
			title: "Host ID",
			dataIndex: "hostId",
			key: "hostId",
			render: (text: string, record: any) => (
				<a
					onClick={(e: any) => {
						e?.domEvent?.stopPropagation();
						history.push("/userDetail/" + text);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Participants",
			dataIndex: "participants",
			key: "participants",
		},
		{
			title: "Duration",
			dataIndex: "duration",
			key: "duration",
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (text: string, record: any) => (
				<Dropdown key="more" overlay={gameSessionMenu(record.id)}>
					<Button
						style={{
							border: "none",
							padding: 0,
						}}
					>
						<EllipsisOutlined
							style={{
								fontSize: 20,
								verticalAlign: "top",
							}}
						/>
					</Button>
				</Dropdown>
			),
		},
	];

	return (
		<Layout>
			<StyledInternalHeader>
				<br />
				<PageHeader
					title={"Game Session Management"}
					style={{ paddingBottom: 0 }}
					extra={[
						<Button
							type="primary"
							key={"create"}
							onClick={() => handleAddGameSession()}
						>
							{"Create new game session"}
						</Button>,
					]}
				></PageHeader>
				<br />
			</StyledInternalHeader>
			<StyledInternalContent>
				<Card>
					<Table
						rowKey="id"
						scroll={{ x: "max-content" }}
						columns={columns}
						dataSource={gameSessionsList}
						pagination={{ pageSize: 50, simple: true, total: 10000 }}
						onChange={onPageChange}
					/>
				</Card>
			</StyledInternalContent>
		</Layout>
	);
};

const mapStateToProps = (state: any) => {
	const { gameType } = state;

	return {
		response: gameType.response,
		gameSessionsList: gameType.gameSessionsList,
	};
};

export default connect(mapStateToProps, {
	getGameSessionsList,
	createGameSession,
	deleteGameSession,
	clearResponse,
})(GameSessionManagementPage);
