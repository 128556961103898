import Request from 'lib/request';
import UserManagementActionTypes from "./types";

export const getUsersList = (limit:number,offset:number) => async (dispatch: any) => {
  try {
    console.log('getting getUsersList')
    const response = await Request.get('/users?offset='+offset+'&limit='+limit)
    const payload = response.data;
    dispatch({ type: UserManagementActionTypes.GET_USERSLIST, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};


export const getUser = (id:string) => async (dispatch: any) => {
  try {
    console.log('getting user',id)
    const response = await Request.get('/admin/users/'+id)
    const payload = response.data;
    dispatch({ type: UserManagementActionTypes.GET_USER, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};

export const registerUser = (body: any) => async (dispatch: any) => {
  try {
    const response = await Request.post('/users/register', body)
    const payload = response.data;
    dispatch({ type: UserManagementActionTypes.REGISTER_USER, payload });
  } catch (error) {
    console.log(error);
  }
};

export const editUser = (body: any,id:string) => async (dispatch: any) => {
  try {
    console.log('editUser',body)
    const response = await Request.patch('/users/'+id, body)
    const payload = response.data;
    dispatch({ type: UserManagementActionTypes.EDIT_USER, payload });
  } catch (error) {
    console.log(error);
  }
};

export const deleteUser = (id:string) => async (dispatch: any) => {
  try {
    console.log('deleting ',id);
    const response = await Request.delete('/users/'+id)
    const payload = response.data;
    dispatch({ type: UserManagementActionTypes.DELETE_USER, payload });
  } catch (error) {
    console.log(error);
  }
};

export const clearResponse = () => async (dispatch: any) => {
  const payload = null;
  dispatch({ type: UserManagementActionTypes.CLEAR_RESPONSE, payload });

};
