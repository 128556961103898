import { Badge, Layout } from "antd";
import styled from "styled-components";

export const RightContainerDiv = styled.div`
	display: flex;
	align-items: center;
`;
export const SliderButtonDiv = styled.div`
	width: 72px;
	height: 72px;
	text-align: center;
	font-size: 30px;
	cursor: pointer;
	align-items: center;
`;

export const StyledBadge = styled(Badge)`
	.iconButton {
		width: 48px;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 24px;
		cursor: pointer;
		.background-hover();

		&:hover {
			.iconFont {
				color: @primary-color;
			}
		}

		& + .iconButton {
			margin-left: 8px;
		}

		.iconFont {
			color: #b2b0c7;
			font-size: 24px;
		}
	}
`;

export const StyledHeader = styled(Layout.Header)<{ headerColor?: string }>`
	padding: 0;
	padding-right: 1rem;
	box-shadow: @shadow-2;
	/* position: relative; */
	position: fixed;
	width: 100vw;
	display: flex;
	justify-content: space-between;
	height: 72px;
	left: 0;
	z-index: 99;
	align-items: center;
	background-color: ${props => props.headerColor ? props.headerColor : '#fff'};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

	.ant-menu-horizontal {
		line-height: 72px;

		& > .ant-menu-submenu:hover {
			color: @primary-color;
			background-color: @hover-color;
		}
	}

	.ant-menu {
		border-bottom: none;
		height: 72px;
	}

	.ant-menu-horizontal > .ant-menu-submenu {
		top: 0;
		margin-top: 0;
	}

	.ant-menu-horizontal > .ant-menu-item,
	.ant-menu-horizontal > .ant-menu-submenu {
		border-bottom: none;
	}

	.ant-menu-horizontal > .ant-menu-item-active,
	.ant-menu-horizontal > .ant-menu-item-open,
	.ant-menu-horizontal > .ant-menu-item-selected,
	.ant-menu-horizontal > .ant-menu-item:hover,
	.ant-menu-horizontal > .ant-menu-submenu-active,
	.ant-menu-horizontal > .ant-menu-submenu-open,
	.ant-menu-horizontal > .ant-menu-submenu-selected,
	.ant-menu-horizontal > .ant-menu-submenu:hover {
		border-bottom: none;
	}
`;

// export const Transition = styled.div`
// .active {
//   visibility: visible;
//   transition: all -200ms ease-in;
// }
// .hidden {
//   visibility: hidden;
//   transition: all -200ms ease-out;
//   transform: translate(0, 0%);
// }
// `;

