import * as yup from "yup";

import {
  Avatar,
  Button,
  Card,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Layout,
  Menu,
  Modal,
  PageHeader,
  Popconfirm,
  Select,
  Table,
} from "antd";
import { EllipsisOutlined, UserOutlined } from "@ant-design/icons";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  clearResponse,
  createReward,
  deleteReward,
  editReward,
  getRewardsList,
} from "redux/rewardManagement/actions";

import { AvatarUpload } from "components/upload/AvatarUpload";
import { StyledInternalContent } from "../components/InternalLayout";
import { StyledInternalHeader } from "../components/InternalLayout";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";

interface Props {}

const RewardEditSchema = yup.object({
  name: yup.string().required(),
});

const defaultInitialValues = {
  name: "",
  type: "PRIZE",
  description: "",
  description2: "",
  image: "",
  eligibleStart: "",
  eligibleEnd: "",
  status: "INACTIVE",
  price: 0,
  availability: 0,
};

const RewardManagementPage: React.FC<Props> = ({
  response,
  rewardsList,
  getRewardsList,
  createReward,
  editReward,
  deleteReward,
  clearResponse,
}: any) => {
  let history = useHistory();
  const [initialValue, setInitial] = useState(defaultInitialValues);
  const [showRewardEditModal, setShowRewardEditModal] = useState(false);
  const [selectedReward, setSelectedReward] = useState<any>();

  console.log("rewardList", rewardsList);

  useEffect(() => {
    console.log("getting Reward List");
    getRewardsList(200, 0);
  }, []);

  useEffect(() => {
    console.log("response", response);

    if (response) {
      console.log("clearingResponse");
      clearResponse();
      setShowRewardEditModal(false);
      getRewardsList(200, 0);
    }
  }, [response]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const handleAddReward = () => {
    setSelectedReward(null);
    setShowRewardEditModal(true);
  };

  const handleEditReward = (id: string) => {
    console.log("id", id, rewardsList);
    setSelectedReward(rewardsList.find((gt: any) => gt.id === id));
    setShowRewardEditModal(true);
  };

  useEffect(() => {
    if (selectedReward) {
      let newInitialFormData = { ...initialValue };
      if (selectedReward.name && selectedReward.name !== "")
        newInitialFormData.name = selectedReward.name;
      if (selectedReward.type && selectedReward.type !== "")
        newInitialFormData.type = selectedReward.type;
      if (selectedReward.description && selectedReward.description !== "")
        newInitialFormData.description = selectedReward.description;
      if (selectedReward.description2 && selectedReward.description2 !== "")
        newInitialFormData.description2 = selectedReward.description2;
      if (selectedReward.image && selectedReward.image !== "")
        newInitialFormData.image = selectedReward.image;
      if (selectedReward.eligibleStart && selectedReward.eligibleStart !== "")
        newInitialFormData.eligibleStart = selectedReward.eligibleStart;
      if (selectedReward.eligibleEnd && selectedReward.eligibleEnd !== "")
        newInitialFormData.eligibleEnd = selectedReward.eligibleEnd;
      if (selectedReward.status && selectedReward.status !== "")
        newInitialFormData.status = selectedReward.status;
      if (selectedReward.price >= 0)
        newInitialFormData.price = selectedReward.price;
      if (selectedReward.availability >= 0)
        newInitialFormData.availability = selectedReward.availability;

      setInitial(newInitialFormData);
      resetMyForm();
    } else {
      setInitial(defaultInitialValues);
      resetMyForm();
    }
  }, [selectedReward]);

  const handleDeleteReward = (id: string) => {
    deleteReward(id);
  };

  const rewardMenu = (id: string) => (
    <Menu>
      <Menu.Item onClick={() => handleEditReward(id)}>{"Edit"}</Menu.Item>
      <Menu.Item>
        <Popconfirm
          title="Are you sure you want to delete this reward?."
          onConfirm={() => handleDeleteReward(id)}
          okText="Yes"
          cancelText="No"
        >
          <a>{"Delete"}</a>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
  const columns: any = [
    {
      title: "Image",
      dataIndex: "avatar",
      key: "avatar",
      render: (text: string, record: any) => (
        <Avatar shape="square" size={64} icon={<UserOutlined />} src={text} />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any) => (
        <a
          onClick={(e: any) => {
            e?.domEvent?.stopPropagation();
            history.push("/rewardDetail/" + record.id);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Description1",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Description2",
      dataIndex: "description2",
      key: "description2",
    },
    {
      title: "Eligible Start",
      dataIndex: "eligibleStart",
      key: "eligibleStart",
      sorter: (a: any, b: any) =>
        a.eligibleStart?.localeCompare(b.eligibleStart),
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "descend",
      render: (text: string) => <span>{moment(text).format("LLL")}</span>,
    },
    {
      title: "Eligible End",
      dataIndex: "eligibleEnd",
      key: "eligibleEnd",
      render: (text: string) => <span>{moment(text).format("LLL")}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Availability",
      dataIndex: "availability",
      key: "availability",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (text: string, record: any) => (
        <Dropdown key="more" overlay={rewardMenu(record.id)}>
          <Button
            style={{
              border: "none",
              padding: 0,
            }}
          >
            <EllipsisOutlined
              style={{
                fontSize: 20,
                verticalAlign: "top",
              }}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  let submitMyForm = () => {};
  let resetMyForm = () => {};
  const handleOk = () => {
    submitMyForm();
  };

  const handleCancel = () => {
    resetMyForm();
    setShowRewardEditModal(false);
  };

  return (
    <Layout>
      <StyledInternalHeader>
        <br />
        <PageHeader
          title={"Reward Management"}
          style={{ paddingBottom: 0 }}
          extra={[
            <Button
              type="primary"
              key={"create"}
              onClick={() => handleAddReward()}
            >
              {"Create new reward"}
            </Button>,
          ]}
        ></PageHeader>
        <br />
      </StyledInternalHeader>
      <StyledInternalContent>
        <Card>
          <Table
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={columns}
            dataSource={rewardsList}
          />
        </Card>
        <Modal
          visible={showRewardEditModal}
          title={"Edit Reward"}
          onOk={() => handleOk()}
          onCancel={() => handleCancel()}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              {"Cancel"}
            </Button>,
            <Button key="accept" type="primary" onClick={handleOk}>
              {"OK"}
            </Button>,
          ]}
        >
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={(data, { setSubmitting }) => {
              console.log("data", selectedReward, data);
              if (selectedReward) {
                editReward(data, selectedReward.id);
              } else {
                createReward(data);
              }
              setSubmitting(false);
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={RewardEditSchema}
          >
            {({
              errors,
              handleSubmit,
              submitForm,
              resetForm,
              values,
              setFieldValue,
            }) => {
              submitMyForm = () => {
                submitForm();
              };
              resetMyForm = () => {
                resetForm();
              };
              return (
                <Form onFinish={handleSubmit} {...formItemLayout}>
                  <Form.Item label="Image">
                    <AvatarUpload
                      value={values.image}
                      onChange={(e: any) => setFieldValue("image", e)}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.name ? "error" : "success"}
                    help={errors.name}
                    label={"Name"}
                    required
                  >
                    <Field
                      name={"name"}
                      placeholder={"name"}
                      type="input"
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.description ? "error" : "success"}
                    help={errors.description}
                    label={"Description 1"}
                    required
                  >
                    <Field
                      name={"description"}
                      placeholder={"Description"}
                      type="input"
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.description2 ? "error" : "success"}
                    help={errors.description2}
                    label={"Description 2"}
                    required
                  >
                    <Field
                      name={"description2"}
                      placeholder={"Description"}
                      type="input"
                      as={Input}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.eligibleStart ? "error" : "success"}
                    help={errors.eligibleStart}
                    label={"Eligible Start"}
                    required
                  >
                    <DatePicker
                      showTime
                      onChange={(date, dateString) => {
                        setFieldValue("eligibleStart", dateString);
                      }}
                      value={
                        values.eligibleStart
                          ? moment(values.eligibleStart)
                          : undefined
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.eligibleEnd ? "error" : "success"}
                    help={errors.eligibleEnd}
                    label={"Eligible End"}
                    required
                  >
                    <DatePicker
                      showTime
                      onChange={(date, dateString) => {
                        setFieldValue("eligibleEnd", dateString);
                      }}
                      value={
                        values.eligibleEnd
                          ? moment(values.eligibleEnd)
                          : undefined
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.status ? "error" : "success"}
                    help={errors.status}
                    label={"Status"}
                    required
                  >
                    <Select
                      placeholder="Select Status"
                      onChange={(value: any) => {
                        setFieldValue("status", value);
                      }}
                      value={values.status}
                    >
                      <Select.Option value="ACTIVE">ACTIVE</Select.Option>
                      <Select.Option value="INACTIVE">INACTIVE</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.price ? "error" : "success"}
                    help={errors.price}
                    label={"Price"}
                  >
                    <InputNumber
                      value={values.price}
                      onChange={(output: any) => setFieldValue("price", output)}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={errors.availability ? "error" : "success"}
                    help={errors.availability}
                    label={"Availability"}
                  >
                    <InputNumber
                      value={values.availability}
                      onChange={(output: any) =>
                        setFieldValue("availability", output)
                      }
                    />
                  </Form.Item>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </StyledInternalContent>
    </Layout>
  );
};

const mapStateToProps = (state: any) => {
  const { rewardManagement } = state;

  return {
    response: rewardManagement.response,
    rewardsList: rewardManagement.rewardsList,
  };
};

export default connect(mapStateToProps, {
  getRewardsList,
  createReward,
  editReward,
  deleteReward,
  clearResponse,
})(RewardManagementPage);
