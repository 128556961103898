import React, { useEffect } from "react";

interface Props {}

const RedirectPage: React.FC<Props> = () => {
  useEffect(() => {
    window.location.replace("http://tosto.re/rockparty3");
  }, []);
  return <div>Redirect...</div>;
};

export default RedirectPage;
