import Request from 'lib/request';
import VideoManagementActionTypes from "./types";

export const getVideosList = (limit:number,offset:number) => async (dispatch: any) => {
  try {
    console.log('getting getVideosList')
    const response = await Request.get('/admin/videos?offset='+offset+'&limit='+limit)
    const payload = response.data;
    dispatch({ type: VideoManagementActionTypes.GET_VIDEOSLIST, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};

export const getVideo = (id:string) => async (dispatch: any) => {
  try {
    console.log('getting user',id)
    const response = await Request.get('/admin/videos/'+id)
    const payload = response.data;
    dispatch({ type: VideoManagementActionTypes.GET_VIDEO, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};

export const getVideoRedeem = (id:string) => async (dispatch: any) => {
  try {
    console.log('getting user',id)
    const response = await Request.get('/videos/videoUnlock/'+id)
    const payload = response.data;
    dispatch({ type: VideoManagementActionTypes.GET_VIDEO_REDEEM, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};


export const createVideo = (body: any) => async (dispatch: any) => {
  try {
    console.log('creating Video',body)
    const response = await Request.post('/admin/videos', body)
    const payload = response.data;
    dispatch({ type: VideoManagementActionTypes.CREATE_VIDEO, payload });
  } catch (error) {
    console.log(error);
    console.log('error',error)
  }
};

export const editVideo = (body: any,id:string) => async (dispatch: any) => {
  try {
    console.log('editVideo',body)
    const response = await Request.patch('/admin/videos/'+id, body)
    const payload = response.data;
    dispatch({ type: VideoManagementActionTypes.EDIT_VIDEO, payload });
  } catch (error) {
    console.log(error);
  }
};

export const deleteVideo = (id:string) => async (dispatch: any) => {
  try {
    console.log('deleting ',id);
    const response = await Request.delete('/admin/videos/'+id)
    const payload = response.data;
    dispatch({ type: VideoManagementActionTypes.DELETE_VIDEO, payload });
  } catch (error) {
    console.log(error);
  }
};

export const clearResponse = () => async (dispatch: any) => {
  const payload = null;
  dispatch({ type: VideoManagementActionTypes.CLEAR_RESPONSE, payload });

};
