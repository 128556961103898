import * as yup from "yup";

import {
	Avatar,
	Button,
	Card,
	Col,
	Dropdown,
	Form,
	Input,
	InputNumber,
	Layout,
	Menu,
	Modal,
	PageHeader,
	Popconfirm,
	Row,
	Table,
} from "antd";
import {
	EllipsisOutlined,
	SolutionOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
	StyledInternalContent,
	StyledInternalHeader,
} from "../components/InternalLayout";
import {
	clearResponse,
	createGamePage,
	deleteGamePage,
	editGamePage,
	getGameModule,
} from "redux/gameType/actions";
import { useHistory, useParams } from "react-router-dom";

import { AvatarUpload } from "components/upload/AvatarUpload";
import { connect } from "react-redux";

interface Props {}

const GamePageEditSchema = yup.object({
	name: yup.string().required(),
});

const defaultInitialValues = {
	name: "",
	question: "",
	answer: "",
	syllable: 0,
	points: 0,
	time: 0,
	firstImage: "",
	secondImage: "",
	gameModuleId: "",
};

const GameModuleDetailPage: React.FC<Props> = ({
	currentGameModule,
	response,
	clearResponse,
	createGamePage,
	deleteGamePage,
	editGamePage,
	getGameModule,
}: any) => {
	let history = useHistory();
	let { gameModuleID } = useParams<any>();
	const [initialValue, setInitial] = useState(defaultInitialValues);
	const [showGamePageEditModal, setShowGamePageEditModal] = useState(false);
	const [selectedGamePage, setSelectedGamePage] = useState<any>();

	useEffect(() => {
		console.log("getting Game Type", gameModuleID);
		getGameModule(gameModuleID);
	}, []);

	useEffect(() => {
		console.log("response", response);

		if (response) {
			console.log("clearingResponse");
			clearResponse();
			setSelectedGamePage(null);
			setShowGamePageEditModal(false);
			getGameModule(gameModuleID);
		}
	}, [response]);

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	};

	const handleAddGamePage = () => {
		setSelectedGamePage(null);
		setShowGamePageEditModal(true);
	};

	const handleEditGamePage = (id: string) => {
		setSelectedGamePage(
			currentGameModule?.gamePages.find((gm: any) => gm.id === id)
		);
		setShowGamePageEditModal(true);
	};
	useEffect(() => {
		if (selectedGamePage) {
			let newInitialFormData = { ...initialValue };
			if (selectedGamePage.name && selectedGamePage.name !== "")
				newInitialFormData.name = selectedGamePage.name;
			if (selectedGamePage.question && selectedGamePage.question !== "")
				newInitialFormData.question = selectedGamePage.question;
			if (selectedGamePage.answer && selectedGamePage.answer !== "")
				newInitialFormData.answer = selectedGamePage.answer;
			if (selectedGamePage.syllable && selectedGamePage.syllable !== 0)
				newInitialFormData.syllable = selectedGamePage.syllable;
			if (selectedGamePage.points && selectedGamePage.points !== 0)
				newInitialFormData.points = selectedGamePage.points;
			if (selectedGamePage.time && selectedGamePage.time !== 0)
				newInitialFormData.time = selectedGamePage.time;
			if (selectedGamePage.firstImage && selectedGamePage.firstImage !== "")
				newInitialFormData.firstImage = selectedGamePage.firstImage;
			if (selectedGamePage.secondImage && selectedGamePage.secondImage !== "")
				newInitialFormData.secondImage = selectedGamePage.secondImage;

			setInitial(newInitialFormData);
			resetMyForm();
		} else {
			setInitial(defaultInitialValues);
			resetMyForm();
		}
	}, [selectedGamePage]);
	const handleDeleteGamePage = (id: string) => {
		deleteGamePage(id);
	};

	const gamePageMenu = (id: string) => (
		<Menu>
			<Menu.Item onClick={() => handleEditGamePage(id)}>{"Edit"}</Menu.Item>
			<Menu.Item>
				<Popconfirm
					title="Are you sure you want to delete this gamePage?."
					onConfirm={() => handleDeleteGamePage(id)}
					okText="Yes"
					cancelText="No"
				>
					<a>{"Delete"}</a>
				</Popconfirm>
			</Menu.Item>
		</Menu>
	);
	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text: string, record: any) => (
				<a
					onClick={(e: any) => {
						e?.domEvent?.stopPropagation();
						history.push("/gamePageDetail/" + record.id);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: "Question",
			dataIndex: "question",
			key: "question",
		},
		{
			title: "Answer",
			dataIndex: "answer",
			key: "answer",
		},
		{
			title: "Syllable",
			dataIndex: "syllable",
			key: "syllable",
		},
		{
			title: "Points",
			dataIndex: "points",
			key: "points",
		},
		{
			title: "Time",
			dataIndex: "time",
			key: "time",
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (text: string, record: any) => (
				<Dropdown key="more" overlay={gamePageMenu(record.id)}>
					<Button
						style={{
							border: "none",
							padding: 0,
						}}
					>
						<EllipsisOutlined
							type="ellipsis"
							style={{
								fontSize: 20,
								verticalAlign: "top",
							}}
						/>
					</Button>
				</Dropdown>
			),
		},
	];

	let submitMyForm = () => {};
	let resetMyForm = () => {};
	const handleOk = () => {
		submitMyForm();
	};

	const handleCancel = () => {
		resetMyForm();
		setShowGamePageEditModal(false);
	};

	return (
		<Layout>
			<StyledInternalHeader>
				<br />
				<PageHeader
					title={"Game Module Detail"}
					style={{ paddingBottom: 0 }}
					extra={[
						<Button
							type="primary"
							key={"create"}
							onClick={() => handleAddGamePage()}
						>
							{"Create new game page"}
						</Button>,
					]}
				></PageHeader>
				<br />
			</StyledInternalHeader>
			<StyledInternalContent>
				{currentGameModule && (
					<Card style={{ marginBottom: 20 }}>
						<Row>
							<Col span={12}>
								<Avatar
									shape="square"
									size={64}
									icon={<UserOutlined />}
									src={currentGameModule.avatar}
								/>
							</Col>
							<Col span={12}>
								<Row>
									<Col xs={8}>ID:</Col>
									<Col xs={16}>{currentGameModule.id}</Col>
								</Row>
								<Row>
									<Col xs={8}>Name:</Col>
									<Col xs={16}>{currentGameModule.name}</Col>
								</Row>
								<Row>
									<Col xs={8}>Cost to unlock:</Col>
									<Col xs={16}>{currentGameModule.costToUnlock}</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				)}
				<Card>
					<Table
						rowKey="id"
						scroll={{ x: "max-content" }}
						columns={columns}
						dataSource={currentGameModule?.gamePages}
					/>
				</Card>
				<Modal
					visible={showGamePageEditModal}
					title={"Edit Game Page"}
					onOk={() => handleOk()}
					onCancel={() => handleCancel()}
					footer={[
						<Button key="cancel" onClick={handleCancel}>
							{"Cancel"}
						</Button>,
						<Button key="accept" type="primary" onClick={handleOk}>
							{"OK"}
						</Button>,
					]}
				>
					<Formik
						enableReinitialize
						initialValues={initialValue}
						onSubmit={(data, { setSubmitting }) => {
							data.gameModuleId = gameModuleID;
							console.log("data", selectedGamePage, data);
							if (selectedGamePage) {
								editGamePage(data, selectedGamePage.id);
							} else {
								createGamePage(data);
							}
							setSubmitting(false);
						}}
						validateOnChange={false}
						validateOnBlur={false}
						validationSchema={GamePageEditSchema}
					>
						{({
							errors,
							handleSubmit,
							submitForm,
							resetForm,
							values,
							setFieldValue,
						}) => {
							submitMyForm = () => {
								submitForm();
							};
							resetMyForm = () => {
								resetForm();
							};
							return (
								<Form onFinish={handleSubmit} {...formItemLayout}>
									<Form.Item
										validateStatus={errors.name ? "error" : "success"}
										help={errors.name}
										label={"Name"}
										required
									>
										<Field
											name={"name"}
											placeholder={"name"}
											type="input"
											as={Input}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.question ? "error" : "success"}
										help={errors.question}
										label={"Question"}
										required
									>
										<Field
											name={"question"}
											placeholder={"question"}
											type="input"
											as={Input}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.answer ? "error" : "success"}
										help={errors.answer}
										label={"Answer"}
										required
									>
										<Field
											name={"answer"}
											placeholder={"answer"}
											type="input"
											as={Input}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.syllable ? "error" : "success"}
										help={errors.syllable}
										label={"Syllable"}
									>
										<InputNumber
											value={values.syllable}
											onChange={(output: any) =>
												setFieldValue("syllable", output)
											}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.points ? "error" : "success"}
										help={errors.points}
										label={"Points"}
									>
										<InputNumber
											value={values.points}
											onChange={(output: any) =>
												setFieldValue("points", output)
											}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.time ? "error" : "success"}
										help={errors.time}
										label={"Time"}
									>
										<InputNumber
											value={values.time}
											onChange={(output: any) => setFieldValue("time", output)}
										/>
									</Form.Item>
									<Form.Item label={"Image 1"}>
										<AvatarUpload
											value={values.firstImage}
											onChange={(e: any) => setFieldValue("firstImage", e)}
										/>
									</Form.Item>
									<Form.Item label={"Image 2"}>
										<AvatarUpload
											value={values.secondImage}
											onChange={(e: any) => setFieldValue("secondImage", e)}
										/>
									</Form.Item>
								</Form>
							);
						}}
					</Formik>
				</Modal>
			</StyledInternalContent>
		</Layout>
	);
};

const mapStateToProps = (state: any) => {
	const { gameType } = state;

	return {
		response: gameType.response,
		currentGameModule: gameType.currentGameModule,
	};
};

export default connect(mapStateToProps, {
	getGameModule,
	createGamePage,
	editGamePage,
	deleteGamePage,
	clearResponse,
})(GameModuleDetailPage);
