import { Card, Col, Layout, PageHeader, Row, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import {
	StyledInternalContent,
	StyledInternalHeader,
} from "components/InternalLayout";
import {
	clearResponse,
	getCapsCount,
	getRedeemedCapsCount,
	getSessionsCount,
	getUsersCount,
} from "redux/settings/actions";

import { connect } from "react-redux";

const DashboardPage: React.FC = ({
	clearResponse,
	getCapsCount,
	getRedeemedCapsCount,
	getSessionsCount,
	getUsersCount,
	caps_count,
	users_count,
	sessions_count,
	redeemed_caps_count,
}: any) => {
	useEffect(() => {
		getCapsCount();
		getSessionsCount();
		getUsersCount();
		getRedeemedCapsCount();
	}, []);

	return (
		<Layout>
			<StyledInternalHeader>
				<br />
				<PageHeader
					title={"Dashboard"}
					style={{ paddingBottom: 0 }}
				></PageHeader>
				<br />
			</StyledInternalHeader>
			<StyledInternalContent>
				<Card>
					<Row gutter={16}>
						<Col span={12}>
							<Statistic title="Current Users" value={users_count} />
						</Col>
						<Col span={12}>
							<Statistic title="Current Game Sessions" value={sessions_count} />
						</Col>
						<Col span={12}>
							<Statistic title="Caps Redeemed" value={redeemed_caps_count} />
						</Col>
						<Col span={12}>
							<Statistic title="Caps Total" value={caps_count} />
						</Col>
					</Row>
				</Card>
			</StyledInternalContent>
		</Layout>
	);
};

const mapStateToProps = (state: any) => {
	const { setting } = state;

	return {
		response: setting.response,
		caps_count: setting.caps_count,
		users_count: setting.users_count,
		sessions_count: setting.sessions_count,
		redeemed_caps_count: setting.redeemed_caps_count,
	};
};

export default connect(mapStateToProps, {
	getCapsCount,
	getRedeemedCapsCount,
	getUsersCount,
	getSessionsCount,
	clearResponse,
})(DashboardPage);
