import * as yup from "yup";

import {
	Avatar,
	Button,
	Card,
	Dropdown,
	Form,
	Input,
	Layout,
	Menu,
	Modal,
	PageHeader,
	Popconfirm,
	Table,
} from "antd";
import {
	EllipsisOutlined,
	SolutionOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
	addCap,
	clearResponse,
	deleteCap,
	getCapsList,
	uploadCapCSV,
} from "redux/capManagement/actions";

import { AvatarUpload } from "components/upload/AvatarUpload";
import { StyledInternalContent } from "../components/InternalLayout";
import { StyledInternalHeader } from "../components/InternalLayout";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

interface Props {}

const CapEditSchema = yup.object({
	code: yup.string().required(),
});

const defaultInitialValues = {
	code: "",
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};

const CapManagementPage: React.FC<Props> = ({
	response,
	capList,
	getCapsList,
	uploadCapCSV,
	deleteCap,
	addCap,
	clearResponse,
}: any) => {
	const [showCapEditModal, setShowCapEditModal] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);

	const onPageChange = (input: any) => {
		console.log("pageChange ", input);
		setCurrentPage(input.current);
	};
	useEffect(() => {
		console.log("getting Game Type List", (currentPage-1)  * 50);
		getCapsList(50, (currentPage-1)  * 50);
	}, [currentPage]);

	useEffect(() => {
		console.log("response", response);

		if (response) {
			console.log("clearingResponse");
			clearResponse();
			setShowCapEditModal(false);
			getCapsList(50, (currentPage-1)  * 50);
		}
	}, [response]);
	const handleUploadCSV = () => {
		setShowUploadModal(true);
	};

	const handleAddCap = () => {
		setShowCapEditModal(true);
	};

	const handleDeleteCap = (id: string) => {
		deleteCap(id);
	};

	const capMenu = (id: string) => (
		<Menu>
			<Menu.Item>
				<Popconfirm
					title="Are you sure you want to delete this gametype?."
					onConfirm={() => handleDeleteCap(id)}
					okText="Yes"
					cancelText="No"
				>
					<a>{"Delete"}</a>
				</Popconfirm>
			</Menu.Item>
		</Menu>
	);
	const columns = [
		{
			title: "Code",
			dataIndex: "code",
			key: "code",
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (text: string, record: any) => (
				<Dropdown key="more" overlay={capMenu(record.id)}>
					<Button
						style={{
							border: "none",
							padding: 0,
						}}
					>
						<EllipsisOutlined
							style={{
								fontSize: 20,
								verticalAlign: "top",
							}}
						/>
					</Button>
				</Dropdown>
			),
		},
	];

	let submitMyForm = () => {};
	let resetMyForm = () => {};
	const handleOk = () => {
		submitMyForm();
	};

	const handleCancel = () => {
		resetMyForm();
		setShowCapEditModal(false);
	};

	return (
		<Layout>
			<StyledInternalHeader>
				<br />
				<PageHeader
					title={"Cap Management"}
					style={{ paddingBottom: 0 }}
					extra={[
						<Button
							type="primary"
							key={"create"}
							onClick={() => handleAddCap()}
						>
							{"Create new cap"}
						</Button>,
					]}
				></PageHeader>
				<br />
			</StyledInternalHeader>
			<StyledInternalContent>
				<Card>
					<Table
						rowKey="id"
						scroll={{ x: "max-content" }}
						columns={columns}
						dataSource={capList}
						pagination={{ pageSize: 50, simple: true, total: 10000 }}
						onChange={onPageChange}
					/>
				</Card>
				<Modal
					visible={showCapEditModal}
					title={"Edit Game Type"}
					onOk={() => handleOk()}
					onCancel={() => handleCancel()}
					footer={[
						<Button key="cancel" onClick={handleCancel}>
							{"Cancel"}
						</Button>,
						<Button key="accept" type="primary" onClick={handleOk}>
							{"OK"}
						</Button>,
					]}
				>
					<Formik
						enableReinitialize
						initialValues={defaultInitialValues}
						onSubmit={(data, { setSubmitting }) => {
							addCap(data);
							setSubmitting(false);
						}}
						validateOnChange={false}
						validateOnBlur={false}
						validationSchema={CapEditSchema}
					>
						{({
							errors,
							handleSubmit,
							submitForm,
							resetForm,
							values,
							setFieldValue,
						}) => {
							submitMyForm = () => {
								submitForm();
							};
							resetMyForm = () => {
								resetForm();
							};
							return (
								<Form onFinish={handleSubmit} {...formItemLayout}>
									<Form.Item
										validateStatus={errors.code ? "error" : "success"}
										help={errors.code}
										label={"Code"}
										required
									>
										<Field
											name={"code"}
											placeholder={"code"}
											type="input"
											as={Input}
										/>
									</Form.Item>
								</Form>
							);
						}}
					</Formik>
				</Modal>
			</StyledInternalContent>
		</Layout>
	);
};

const mapStateToProps = (state: any) => {
	const { capManagement } = state;

	return {
		response: capManagement.response,
		capList: capManagement.capList,
	};
};

export default connect(mapStateToProps, {
	addCap,
	getCapsList,
	uploadCapCSV,
	deleteCap,
	clearResponse,
})(CapManagementPage);
