const SettingsActionTypes = {
  GET_VERSION: 'GET_VERSION',
  EDIT_VERSION: 'EDIT_VERSION',
  GET_PROMOTION: 'GET_PROMOTION',
  EDIT_PROMOTION: 'EDIT_PROMOTION',
  CLEAR_RESPONSE: 'CLEAR_RESPONSE',
  GET_CAPS_COUNT: 'GET_CAPS_COUNT',
  GET_REDEEMED_CAPS_COUNT: 'GET_REDEEMED_CAPS_COUNT',
  GET_USERS_COUNT: 'GET_USERS_COUNT',
  GET_SESSIONS_COUNT: 'GET_SESSIONS_COUNT',
};

export default SettingsActionTypes;
