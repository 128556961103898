import axios from 'axios';

export const customAxios = axios.create({
  baseURL:
    process.env.REACT_APP_API_ENDPOINT ||
    'http://localhost:8000'
});

export default class Request {
  static getHeaders() {
    
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem(
        'access-token'
      )}`,
    };
  }

  static async get(uri: string, params = {}) {
    try {
      const response = await customAxios.get(uri, {
        data: params,
        headers: Request.getHeaders()
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async post(uri: string, params = {}) {
    try {
      const response = await customAxios.post(uri, params, {
        headers: Request.getHeaders()
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async patch(uri: string, params = {}) {
    try {
      const response = await customAxios.patch(uri, params, {
        headers: Request.getHeaders()
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async put(uri: string, params = {}) {
    try {
      const response = await customAxios.put(uri, params, {
        headers: Request.getHeaders()
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async delete(uri: string) {
    try {
      const response = await customAxios.delete(uri, {
        headers: Request.getHeaders()
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
}
