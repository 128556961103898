import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import createRootReducer from 'redux/reducers';

const isDev = process.env.NODE_ENV === 'development';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const history = createBrowserHistory();
export const middlewares = [thunk];

const composeEnhancers = (isDev
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : null) || compose;

const reducers = createRootReducer(history);

export default () => {
  const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(routerMiddleware(history), ...middlewares))
  );
  return { store  };
};
