import Request from 'lib/request';
import RewardManagementActionTypes from "./types";

export const getRewardsList = (limit:number,offset:number) => async (dispatch: any) => {
  try {
    console.log('getting getRewardsList')
    const response = await Request.get('/admin/rewards?type=PRIZE&offset='+offset+'&limit='+limit)
    const payload = response.data;
    dispatch({ type: RewardManagementActionTypes.GET_REWARDSLIST, payload });
    console.log('payload getRewardsList',payload)
  } catch (error) {
    console.log(error);
  }
};
export const getLotterysList = (limit:number,offset:number) => async (dispatch: any) => {
  try {
    console.log('getting getLotterysList')
    const response = await Request.get('/admin/rewards?type=LOTTERY&offset='+offset+'&limit='+limit)
    const payload = response.data;
    dispatch({ type: RewardManagementActionTypes.GET_LOTTERYLIST, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};
export const getReward = (id:string) => async (dispatch: any) => {
  try {
    console.log('getting getReward',id)
    const response = await Request.get('/admin/rewards/'+id)
    const payload = response.data;
    dispatch({ type: RewardManagementActionTypes.GET_REWARD, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};

export const getRewardRedemption = (id:string) => async (dispatch: any) => {
  try {
    console.log('getting getRewardRedemption',id)
    const response = await Request.get('/admin/reward-redemptions/'+id)
    const payload = response.data;
    dispatch({ type: RewardManagementActionTypes.GET_REWARD_REDEMPTION, payload });
    console.log('payload',payload)
  } catch (error) {
    console.log(error);
  }
};

export const createReward = (body: any) => async (dispatch: any) => {
  try {
    console.log('creating reward',body)
    const response = await Request.post('/admin/rewards', body)
    const payload = response.data;
    dispatch({ type: RewardManagementActionTypes.CREATE_REWARD, payload });
  } catch (error) {
    console.log('error reward',error)
    console.log(error);
  }
};

export const createLottery = (body: any) => async (dispatch: any) => {
  try {
    const response = await Request.post('/admin/rewards', body)
    const payload = response.data;
    dispatch({ type: RewardManagementActionTypes.CREATE_LOTTERY, payload });
  } catch (error) {
    console.log(error);
  }
};

export const editReward = (body: any,id:string) => async (dispatch: any) => {
  try {
    console.log('editReward',body)
    const response = await Request.patch('/admin/rewards/'+id, body)
    const payload = response.data;
    dispatch({ type: RewardManagementActionTypes.EDIT_REWARD, payload });
  } catch (error) {
    console.log(error);
  }
};

export const deleteReward = (id:string) => async (dispatch: any) => {
  try {
    console.log('deleting ',id);
    const response = await Request.delete('/admin/rewards/'+id)
    const payload = response.data;
    dispatch({ type: RewardManagementActionTypes.DELETE_REWARD, payload });
  } catch (error) {
    console.log(error);
  }
};

export const deleteRedemption = (id:string) => async (dispatch: any) => {
  try {
    console.log('deleting ',id);
    const response = await Request.delete('/admin/rewards/'+id)
    const payload = response.data;
    dispatch({ type: RewardManagementActionTypes.DELETE_REDEMPTION, payload });
  } catch (error) {
    console.log(error);
  }
};

export const clearResponse = () => async (dispatch: any) => {
  const payload = null;
  dispatch({ type: RewardManagementActionTypes.CLEAR_RESPONSE, payload });

};
