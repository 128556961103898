const UserManagementActionTypes = {
  GET_USERSLIST: 'GET_USERSLIST',
  GET_USER: 'GET_USER',
  REGISTER_USER: 'REGISTER_USER',
  EDIT_USER: 'EDIT_USER',
  DELETE_USER: 'DELETE_USER',
  CLEAR_RESPONSE: 'CLEAR_RESPONSE',
};

export default UserManagementActionTypes;
