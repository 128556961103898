import * as yup from "yup";

import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Layout,
  Menu,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Table,
} from "antd";
import { EllipsisOutlined, SolutionOutlined } from "@ant-design/icons";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  StyledInternalContent,
  StyledInternalHeader,
} from "../components/InternalLayout";
import {
  clearResponse,
  deleteRedemption,
  getReward,
} from "redux/rewardManagement/actions";
import { useHistory, useParams } from "react-router-dom";

import { AvatarUpload } from "components/upload/AvatarUpload";
import { UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";

interface Props {}

const RewardDetailPage: React.FC<Props> = ({
  currentReward,
  response,
  getReward,
  deleteRedemption,
  clearResponse,
}: any) => {
  console.log("currentReward", currentReward);
  let history = useHistory();
  let { rewardID } = useParams<any>();

  useEffect(() => {
    console.log("getting Reward Detail", rewardID);
    getReward(rewardID);
  }, []);

  useEffect(() => {
    console.log("response", response);

    if (response) {
      console.log("clearingResponse");
      clearResponse();
      getReward(rewardID);
    }
  }, [response]);

  const handleDeleteRedemption = (id: string) => {
    deleteRedemption(id);
  };

  const gameModuleMenu = (id: string) => (
    <Menu>
      <Menu.Item>
        <Popconfirm
          title="Are you sure you want to delete this reward redemption?."
          onConfirm={() => handleDeleteRedemption(id)}
          okText="Yes"
          cancelText="No"
        >
          <a>{"Delete"}</a>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
  const columns: any = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      sorter: (a: any, b: any) => a.number - b.number,
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "descend",
    },
    {
      title: "Redeemer",
      dataIndex: "userId",
      key: "userId",
      render: (text: string, record: any) => (
        <a
          onClick={(e: any) => {
            e?.domEvent?.stopPropagation();
            history.push("/userDetail/" + text);
          }}
        >
          {decodeURIComponent(record.user.username)}
        </a>
      ),
    },
    {
      title: "Redeem Date",
      dataIndex: "redeemDate",
      key: "redeemDate",
      render: (text: string) => <span>{moment(text).format("LLL")}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Name",
      dataIndex: "userId",
      key: "firstName",
      render: (text: string, record: any) => (
        <span>
          {decodeURIComponent(record.user.firstName)}{" "}
          {decodeURIComponent(record.user.lastName)}
        </span>
      ),
    },
    {
      title: "Address",
      dataIndex: "userId",
      key: "address",
      render: (text: string, record: any) => (
        <span>
          {decodeURIComponent(record.user.address).split("+").join(" ")}
        </span>
      ),
    },
    {
      title: "Tel",
      dataIndex: "userId",
      key: "tel",
      render: (text: string, record: any) => <span>{record.user.tel}</span>,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (text: string, record: any) => (
        <Dropdown key="more" overlay={gameModuleMenu(record.id)}>
          <Button
            style={{
              border: "none",
              padding: 0,
            }}
          >
            <EllipsisOutlined
              style={{
                fontSize: 20,
                verticalAlign: "top",
              }}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <Layout>
      <StyledInternalHeader>
        <br />
        <PageHeader
          title={"Reward Detail"}
          style={{ paddingBottom: 0 }}
        ></PageHeader>
        <br />
      </StyledInternalHeader>
      <StyledInternalContent>
        {currentReward && (
          <Card style={{ marginBottom: 20 }}>
            <Row>
              <Col span={12}>
                <Avatar
                  shape="square"
                  size={64}
                  icon={<UserOutlined />}
                  src={currentReward.image}
                />
              </Col>

              <Col span={12}>
                <Row>
                  <Col xs={8}>Name:</Col>
                  <Col xs={16}>{currentReward.name}</Col>
                </Row>
                <Row>
                  <Col xs={8}>Type:</Col>
                  <Col xs={16}>{currentReward.type}</Col>
                </Row>
                <Row>
                  <Col xs={8}>Description1:</Col>
                  <Col xs={16}>{currentReward.description1}</Col>
                </Row>
                <Row>
                  <Col xs={8}>Description2:</Col>
                  <Col xs={16}>{currentReward.description2}</Col>
                </Row>
                <Row>
                  <Col xs={8}>Eligible Start:</Col>
                  <Col xs={16}>
                    {moment(currentReward.eligibleStart).format("LLL")}
                  </Col>
                </Row>
                <Row>
                  <Col xs={8}>Eligible End:</Col>
                  <Col xs={16}>
                    {moment(currentReward.eligibleEnd).format("LLL")}
                  </Col>
                </Row>
                <Row>
                  <Col xs={8}>Status:</Col>
                  <Col xs={16}>{currentReward.status}</Col>
                </Row>
                <Row>
                  <Col xs={8}>Price:</Col>
                  <Col xs={16}>{currentReward.price}</Col>
                </Row>
                <Row>
                  <Col xs={8}>Availability:</Col>
                  <Col xs={16}>{currentReward.availability}</Col>
                </Row>
              </Col>
            </Row>
          </Card>
        )}
        {currentReward && (
          <Card>
            <Table
              rowKey="id"
              scroll={{ x: "max-content" }}
              columns={columns}
              dataSource={currentReward?.rewardRedemptions}
            />
          </Card>
        )}
      </StyledInternalContent>
    </Layout>
  );
};

const mapStateToProps = (state: any) => {
  const { rewardManagement } = state;

  return {
    response: rewardManagement.response,
    currentReward: rewardManagement.currentReward,
  };
};

export default connect(mapStateToProps, {
  getReward,
  deleteRedemption,
  clearResponse,
})(RewardDetailPage);
