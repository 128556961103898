const dashboard = {
	roleDataName: "dashboard",
	name: "dashboard",
	title: "dashboard",
	icon: "windows",
	link: "/dashboard",
};
const userManagement = {
	roleDataName: "userManagement",
	name: "usermanagement",
	title: "user-management",
	icon: "user",
	link: "/user-management",
};
const gameManagement = {
	roleDataName: "gameManagement",
	name: "gamemanagement",
	title: "game-management",
	icon: "game",
	link: "/game-management",
};
const gameSessionManagement = {
	roleDataName: "gameSessionManagement",
	name: "game-session-management",
	title: "game-session-management",
	icon: "game",
	link: "/session-management",
};
const capManagement = {
	roleDataName: "capManagement",
	name: "cap-management",
	title: "cap-management",
	icon: "game",
	link: "/cap-management",
};
const videoManagement = {
	roleDataName: "videoManagement",
	name: "video-management",
	title: "video-management",
	icon: "game",
	link: "/video-management",
};
const rewardManagement = {
	roleDataName: "rewardManagement",
	name: "reward-management",
	title: "reward-management",
	icon: "game",
	link: "/reward-management",
};
const lotteryManagement = {
	roleDataName: "lotteryManagement",
	name: "lottery-management",
	title: "lottery-management",
	icon: "game",
	link: "/lottery-management",
};

const settings = {
	roleDataName: "settings",
	name: "settings",
	title: "setting",
	icon: "setting",
	link: "/settings",
};

export const NavigationData = [
	dashboard,
	userManagement,
	gameManagement,
	gameSessionManagement,
	capManagement,
	videoManagement,
	rewardManagement,
	lotteryManagement,
	settings,
];
