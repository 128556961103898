import UserManagementActionTypes from './types';

const INITIAL_STATE = {
  response:null,
  currentUser:null,
  userList:null,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UserManagementActionTypes.GET_USER:
      return { ...state, currentUser: action.payload };
    case UserManagementActionTypes.GET_USERSLIST:
      return { ...state, userList: action.payload };
    case UserManagementActionTypes.REGISTER_USER:
      return { ...state, response: action.payload };
    case UserManagementActionTypes.EDIT_USER:
      return { ...state, response: action.payload };
    case UserManagementActionTypes.DELETE_USER:
      return { ...state, response: action.payload };
      
    case UserManagementActionTypes.CLEAR_RESPONSE:
      return { ...state, response: action.payload };


    default:
      return state;
  }
};
