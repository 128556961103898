import GameTypeActionTypes from './types';

const INITIAL_STATE = {
  response:null,
  currentGameType:null,
  currentGameModule:null,
  currentGamePage:null,
  gameTypeList:null,
  gameSessionsList:null
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GameTypeActionTypes.GET_GAMETYPE:
      return { ...state, currentGameType: action.payload };
    case GameTypeActionTypes.GET_GAMETYPELIST:
      return { ...state, gameTypeList: action.payload };
    case GameTypeActionTypes.CREATE_GAMETYPE:
      return { ...state, response: action.payload };
    case GameTypeActionTypes.EDIT_GAMETYPE:
      return { ...state, response: action.payload };
    case GameTypeActionTypes.DELETE_GAMETYPE:
      return { ...state, response: action.payload };
      
    case GameTypeActionTypes.GET_GAMEMODULE:
      return { ...state, currentGameModule: action.payload };
    case GameTypeActionTypes.CREATE_GAMEMODULE:
      return { ...state, response: action.payload };
    case GameTypeActionTypes.EDIT_GAMEMODULE:
      return { ...state, response: action.payload };
    case GameTypeActionTypes.DELETE_GAMEMODULE:
      return { ...state, response: action.payload };
      
    case GameTypeActionTypes.GET_GAMEPAGE:
      return { ...state, currentGamePage: action.payload };
    case GameTypeActionTypes.CREATE_GAMEPAGE:
      return { ...state, response: action.payload };
    case GameTypeActionTypes.EDIT_GAMEPAGE:
      return { ...state, response: action.payload };
    case GameTypeActionTypes.DELETE_GAMEPAGE:
      return { ...state, response: action.payload };
      
    case GameTypeActionTypes.CREATE_GAMEHINT:
      return { ...state, response: action.payload };
    case GameTypeActionTypes.EDIT_GAMEHINT:
      return { ...state, response: action.payload };
    case GameTypeActionTypes.DELETE_GAMEHINT:
      return { ...state, response: action.payload };
      
      case GameTypeActionTypes.CREATE_GAMEPOINT:
        return { ...state, response: action.payload };
      case GameTypeActionTypes.EDIT_GAMEPOINT:
        return { ...state, response: action.payload };
      case GameTypeActionTypes.DELETE_GAMEPOINT:
        return { ...state, response: action.payload };

    case GameTypeActionTypes.GET_GAMESESSIONLIST:
      return { ...state, gameSessionsList: action.payload };
    case GameTypeActionTypes.CREATE_GAMESESSION:
      return { ...state, response: action.payload };
    case GameTypeActionTypes.DELETE_GAMESESSION:
      return { ...state, response: action.payload };

    case GameTypeActionTypes.CLEAR_RESPONSE:
      return { ...state, response: action.payload };

    default:
      return state;
  }
};
