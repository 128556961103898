import RewardManagementActionTypes from './types';

const INITIAL_STATE = {
  response:null,
  currentReward:null,
  redemptionsList:null,
  rewardsList:null,
  lotteryList:null,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case RewardManagementActionTypes.GET_REWARD:
      return { ...state, currentReward: action.payload };
    case RewardManagementActionTypes.GET_REWARD_REDEMPTION:
      return { ...state, redemptionsList: action.payload };
    case RewardManagementActionTypes.GET_REWARDSLIST:
      return { ...state, rewardsList: action.payload };
    case RewardManagementActionTypes.CREATE_REWARD:
      return { ...state, response: action.payload };
    case RewardManagementActionTypes.CREATE_LOTTERY:
      return { ...state, response: action.payload };
    case RewardManagementActionTypes.EDIT_REWARD:
      return { ...state, response: action.payload };
    case RewardManagementActionTypes.DELETE_REWARD:
      return { ...state, response: action.payload };
    case RewardManagementActionTypes.DELETE_REDEMPTION:
      return { ...state, response: action.payload };
    case RewardManagementActionTypes.GET_LOTTERYLIST:
      return { ...state, lotteryList: action.payload };
    case RewardManagementActionTypes.CLEAR_RESPONSE:
      return { ...state, response: action.payload };


    default:
      return state;
  }
};
