import * as yup from "yup";

import {
	Avatar,
	Button,
	Card,
	Col,
	Dropdown,
	Form,
	Input,
	InputNumber,
	Layout,
	Menu,
	Modal,
	PageHeader,
	Popconfirm,
	Row,
	Table,
} from "antd";
import { EllipsisOutlined, SolutionOutlined } from "@ant-design/icons";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
	StyledInternalContent,
	StyledInternalHeader,
} from "../components/InternalLayout";
import {
	clearResponse,
	createGameModule,
	deleteGameModule,
	editGameModule,
	getGameType,
} from "redux/gameType/actions";
import { useHistory, useParams } from "react-router-dom";

import { AvatarUpload } from "components/upload/AvatarUpload";
import { UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

interface Props {}

const GameModuleEditSchema = yup.object({
	name: yup.string().required(),
});

const defaultInitialValues = {
	name: "",
	costToUnlock: 0,
	avatar: "",
	gameTypeId: "",
};

const GameTypeDetailPage: React.FC<Props> = ({
	currentGameType,
	response,
	getGameType,
	createGameModule,
	deleteGameModule,
	editGameModule,
	clearResponse,
}: any) => {
	console.log("currentGameType", currentGameType);
	let history = useHistory();
	let { gameTypeID } = useParams<any>();
	const [initialValue, setInitial] = useState(defaultInitialValues);
	const [showGameModuleEditModal, setShowGameModuleEditModal] = useState(false);
	const [selectedGameModule, setSelectedGameModule] = useState<any>();

	useEffect(() => {
		console.log("getting Game Type", gameTypeID);
		getGameType(gameTypeID);
	}, []);

	useEffect(() => {
		console.log("response", response);

		if (response) {
			console.log("clearingResponse");
			clearResponse();
			setSelectedGameModule(null);
			setShowGameModuleEditModal(false);
			getGameType(gameTypeID);
		}
	}, [response]);

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	};

	const handleAddGameModule = () => {
		setSelectedGameModule(null);
		setShowGameModuleEditModal(true);
	};

	const handleEditGameModule = (id: string) => {
		setSelectedGameModule(
			currentGameType?.gameModules.find((gm: any) => gm.id === id)
		);
		setShowGameModuleEditModal(true);
	};

	useEffect(() => {
		if (selectedGameModule) {
			let newInitialFormData = { ...initialValue };
			if (selectedGameModule.name && selectedGameModule.name !== "")
				newInitialFormData.name = selectedGameModule.name;
			if (selectedGameModule.avatar && selectedGameModule.avatar !== "")
				newInitialFormData.avatar = selectedGameModule.avatar;
			if (
				selectedGameModule.costToUnlock &&
				selectedGameModule.costToUnlock !== 0
			)
				newInitialFormData.costToUnlock = selectedGameModule.costToUnlock;
			setInitial(newInitialFormData);
			resetMyForm();
		} else {
			setInitial(defaultInitialValues);
			resetMyForm();
		}
	}, [selectedGameModule]);

	const handleDeleteGameModule = (id: string) => {
		deleteGameModule(id);
	};

	const gameModuleMenu = (id: string) => (
		<Menu>
			<Menu.Item onClick={() => handleEditGameModule(id)}>{"Edit"}</Menu.Item>
			<Menu.Item>
				<Popconfirm
					title="Are you sure you want to delete this gameModule?."
					onConfirm={() => handleDeleteGameModule(id)}
					okText="Yes"
					cancelText="No"
				>
					<a>{"Delete"}</a>
				</Popconfirm>
			</Menu.Item>
		</Menu>
	);
	const columns = [
		{
			title: "Image",
			dataIndex: "avatar",
			key: "avatar",
			render: (text: string, record: any) => (
				<Avatar shape="square" size={64} icon={<UserOutlined />} src={text} />
			),
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text: string, record: any) => (
				<a
					onClick={(e: any) => {
						e?.domEvent?.stopPropagation();
						history.push("/gameModuleDetail/" + record.id);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: "Cost To Unlock",
			dataIndex: "costToUnlock",
			key: "costToUnlock",
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (text: string, record: any) => (
				<Dropdown key="more" overlay={gameModuleMenu(record.id)}>
					<Button
						style={{
							border: "none",
							padding: 0,
						}}
					>
						<EllipsisOutlined
							style={{
								fontSize: 20,
								verticalAlign: "top",
							}}
						/>
					</Button>
				</Dropdown>
			),
		},
	];

	let submitMyForm = () => {};
	let resetMyForm = () => {};
	const handleOk = () => {
		submitMyForm();
	};

	const handleCancel = () => {
		resetMyForm();
		setShowGameModuleEditModal(false);
	};

	return (
		<Layout>
			<StyledInternalHeader>
				<br />
				<PageHeader
					title={"Game Type Detail"}
					style={{ paddingBottom: 0 }}
					extra={[
						<Button
							type="primary"
							key={"create"}
							onClick={() => handleAddGameModule()}
						>
							{"Create new Game Module"}
						</Button>,
					]}
				></PageHeader>
				<br />
			</StyledInternalHeader>
			<StyledInternalContent>
				{currentGameType && (
					<Card style={{ marginBottom: 20 }}>
						<Row>
							<Col span={12}>
								<Avatar
									shape="square"
									size={64}
									icon={<UserOutlined />}
									src={currentGameType.avatar}
								/>
							</Col>

							<Col span={12}>
								<Row>
									<Col xs={8}>ID:</Col>
									<Col xs={16}>{currentGameType.id}</Col>
								</Row>
								<Row>
									<Col xs={8}>Name:</Col>
									<Col xs={16}>{currentGameType.name}</Col>
								</Row>
								<Row>
									<Col xs={8}>Display Name:</Col>
									<Col xs={16}>{currentGameType.displayName}</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				)}
				{currentGameType && (
					<Card>
						<Table
							rowKey="id"
							scroll={{ x: "max-content" }}
							columns={columns}
							dataSource={currentGameType?.gameModules}
						/>
					</Card>
				)}
				<Modal
					visible={showGameModuleEditModal}
					title={"Edit Game Module"}
					onOk={() => handleOk()}
					onCancel={() => handleCancel()}
					footer={[
						<Button key="cancel" onClick={handleCancel}>
							{"Cancel"}
						</Button>,
						<Button key="accept" type="primary" onClick={handleOk}>
							{"OK"}
						</Button>,
					]}
				>
					<Formik
						enableReinitialize
						initialValues={initialValue}
						onSubmit={(data, { setSubmitting }) => {
							data.gameTypeId = gameTypeID;
							console.log("data", selectedGameModule, data);
							if (selectedGameModule) {
								editGameModule(data, selectedGameModule.id);
							} else {
								createGameModule(data);
							}
							setSubmitting(false);
						}}
						validateOnChange={false}
						validateOnBlur={false}
						validationSchema={GameModuleEditSchema}
					>
						{({
							errors,
							handleSubmit,
							submitForm,
							resetForm,
							values,
							setFieldValue,
						}) => {
							submitMyForm = () => {
								submitForm();
							};
							resetMyForm = () => {
								resetForm();
							};
							return (
								<Form onFinish={handleSubmit} {...formItemLayout}>
									<Form.Item label={"Image"}>
										<AvatarUpload
											value={values.avatar}
											onChange={(e: any) => setFieldValue("avatar", e)}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.name ? "error" : "success"}
										help={errors.name}
										label={"Name"}
										required
									>
										<Field
											name={"name"}
											placeholder={"name"}
											type="input"
											as={Input}
										/>
									</Form.Item>
									<Form.Item
										validateStatus={errors.costToUnlock ? "error" : "success"}
										help={errors.costToUnlock}
										label={"Cost To Unlock"}
									>
										<InputNumber
											value={values.costToUnlock}
											onChange={(output: any) =>
												setFieldValue("costToUnlock", output)
											}
										/>
									</Form.Item>
								</Form>
							);
						}}
					</Formik>
				</Modal>
			</StyledInternalContent>
		</Layout>
	);
};

const mapStateToProps = (state: any) => {
	const { gameType } = state;

	return {
		response: gameType.response,
		currentGameType: gameType.currentGameType,
	};
};

export default connect(mapStateToProps, {
	getGameType,
	createGameModule,
	editGameModule,
	deleteGameModule,
	clearResponse,
})(GameTypeDetailPage);
