import UserActionTypes from './types';

const INITIAL_STATE = {
  currentUser: null,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UserActionTypes.LOGIN:
      return { ...state, currentUser: action.payload };
    case UserActionTypes.LOGOUT:
      return { ...state, currentUser: null };
    case UserActionTypes.RESETPASSWORD:
      return { ...state, currentUser: null };
    case UserActionTypes.FORGET_PASSWORD:
      return { ...state, currentUser: null };
    default:
      return state;
  }
};
