import { Layout } from "antd";
import styled from "styled-components";

export const StyledContainerLayout = styled(Layout)`
	height: 100vh;
	flex: 1;
	width: ~"calc(100% - 256px)";
	//overflow-y: scroll;
	overflow-x: hidden;
	// font-family: 'Kanit';
`;

export const Transition = styled.div`
	.open {
		width: calc(100vw);
	}
	.close {
		width: 100vw;
	}
`;

export const StyledContent = styled(Layout.Content)`
	min-height: ~"calc(100% - 72px)";
	// overflow-y: scroll;
	display: flex;
	flex-direction: column;
	// font-family: 'Kanit';
`;
